import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomepageTemplate from "./HomepageTemplate";
import LoginTemplate from "./LoginTemplate";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Contacts from "./pages/contacts/Contacts";
import Groups from "./pages/groups/Groups";
import SenderID from "./pages/senderid/SenderID";
import Campaign from "./pages/campaign/Campaign";
import SmsTemplate from "./pages/sms-template/SmsTemplate";
import ApiTemplate from "./pages/api-template/ApiTemplate";
import DeliveryReports from "./pages/reports/DeliveryReports";
import ReplyReports from "./pages/reports/ReplyReports";
import StopReports from "./pages/reports/StopReports";
import VoiceAI from "./pages/ai/VoiceAI";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";

var userInfo = localStorage.getItem("user_info");

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {!userInfo?
          <Route path="/" element={<LoginTemplate />}>
            <Route index element={<Login />} />
          </Route>
        :
          <Route path="/" element={<HomepageTemplate />}>
            <Route index element={<Dashboard />} />
            <Route path="user" element={<Users />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="groups" element={<Groups />} />
            <Route path="senderid" element={<SenderID />} />
            <Route path="campaign" element={<Campaign />} />
            <Route path="sms-template" element={<SmsTemplate />} />
            <Route path="api-template" element={<ApiTemplate />} />
            <Route path="voice_ai" element={<VoiceAI />} />
            <Route path="delivery_reports" element={<DeliveryReports />} />
            <Route path="reply_reports" element={<ReplyReports />} />
            <Route path="stop_reports" element={<StopReports />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        }
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);