import React, {useEffect} from 'react';
import axios from 'axios';
import HostConnect from './HostConnect';

const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";

const getContactList = (data) => {
  return axios.post(API_URL + "contact/view/all", {
    data: data,
  })
};

const addContact = (data) => {
  return axios.post(API_URL + "contact/add", {
    data: data,
  })
}

const viewEditForm = (data) => {
  return axios.post(API_URL + "contact/view", {
    data: data,
  })
}

const updateContactInfo = (data) => {
  return axios.post(API_URL + "contact/update", {
    data: data,
  })
}

const disableContact = (data,groupTemp) => {
  return axios.post(API_URL + "contact/disable", {
    data: data,
    groupTemp:groupTemp
  })
}

const unsubscribeContact = (data,groupTemp) => {
  return axios.post(API_URL + "contact/unsubscribe", {
    data: data,
    groupTemp:groupTemp
  })
}

const enableContact = (data) => {
  return axios.post(API_URL + "contact/enable", {
    data: data,
  })
}

const addSenderID = (data) => {
  return axios.post(API_URL + "contact/add-senderid", {
    data: data,
  })
}

const getSenderIDList = () => {
  return axios.post(API_URL + "contact/view/senderid");
};

const disableSenderID = (data) => {
  return axios.post(API_URL + "contact/disable-senderid", {
    data: data,
  })
}

const enableSenderID = (data) => {
  return axios.post(API_URL + "contact/enable-senderid", {
    data: data,
  })
}

const getSenderIDListEnabled = () => {
  return axios.post(API_URL + "contact/view/senderid-enabled");
};

const viewEditSenderid = (data) => {
  return axios.post(API_URL + "contact/view-senderid", {
    data: data,
  })
}

const updateSenderidInfo = (data) => {
  return axios.post(API_URL + "contact/update-senderid", {
    data: data,
  })
}

const addGroup = (data) => {
  return axios.post(API_URL + "contact/add-group", {
    data: data,
  })
}

const getGroupList = () => {
  return axios.post(API_URL + "contact/view/group");
};

const disableGroup = (data,groupName) => {
  return axios.post(API_URL + "contact/disable-group", {
    data: data,
    description:groupName
  })
}

const enableGroup = (data) => {
  return axios.post(API_URL + "contact/enable-group", {
    data: data,
  })
}

const viewEditGroup = (data) => {
  return axios.post(API_URL + "contact/view-group", {
    data: data,
  })
}

const updatGroupInfo = (data) => {
  return axios.post(API_URL + "contact/update-group", {
    data: data,
  })
}

const getGroupistEnabled = () => {
  return axios.post(API_URL + "contact/view/group-enabled");
};

const importContact = (data) => {
  const formData = new FormData();
  formData.append("file", data);

  return axios
  .post(API_URL + "import", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const updateContactSenderID = (data) => {
  return axios.post(API_URL + "contact/update-senderid-selected", {
    data: data,
  })
}


const ContactService = {
    importContact,
    addContact,
    getContactList,
    viewEditForm,
    updateContactInfo,
    disableContact,
    enableContact,
    addSenderID,
    getSenderIDList,
    enableSenderID,
    disableSenderID,
    getSenderIDListEnabled,
    viewEditSenderid,
    updateSenderidInfo,
    addGroup,
    getGroupList,
    disableGroup,
    enableGroup,
    viewEditGroup,
    updatGroupInfo,
    getGroupistEnabled,
    updateContactSenderID,
    unsubscribeContact
}
export default ContactService;