import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { Paginator } from 'primereact/paginator';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import Select from 'react-select';
import EditContact from './EditContact'
import SmsContact from './SmsContact'
import EditSenderid from './EditSenderid'
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Contacts = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contacts,setContacts] = React.useState([])
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const [openUpdateSelectedSenderID, setOpenUpdateSelectedSenderID] = React.useState(false);
    const [groups,setGroups] = React.useState([])
    const [group,setGroup] = React.useState([])
    const [groupTemp,setGroupTemp] = React.useState('')
    const handleEditForm= () => setOpenEditForm(false);
    const [file, setFile] = useState([]);
    const [editFormSenderID,setEditFormSenderID] = React.useState(null)
    const handleUpdateSelectedSenderID= () => setEditFormSenderID(false);

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [loadingSenderid,setLoadingSenderid] = React.useState(false)
    const [loadingGroup,setLoadingGroup] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)
    const [editFormSenderIDContent,setEditFormSenderIDContent] = React.useState(null)

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        console.log(event.rows)
        console.log(event.first)
    };
    
    
    const [to,setTo] = React.useState([])

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);

    const [openNotif, setOpenNotif] = React.useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [updateSelectedSenderIDBol,setUpdateSelectedSenderIDBol] = React.useState(false);
  
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        group: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        senderid: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {
        getGroup()
        getSenderID()

    }, []);

    const selectedContactList = (e) => {
        if(e.value.length > 0){
            setUpdateSelectedSenderIDBol(true)
        }
        else{
            setUpdateSelectedSenderIDBol(false)
        }
        setSelectedContact(e.value)
    }

    const getSenderID = () => {
        setLoadingSenderid(true)
        try{
            ContactService.getSenderIDListEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].phone,
                        label:response.data.message[x].phone
                    })
                }
                setLoadingSenderid(false)
                setContacts(senderidArr)
            });
        }catch(err){
            setLoadingSenderid(false)
            console.log(err)
        }
    }

    const getGroup = () => {
        setLoadingGroup(true);
        try{
            ContactService.getGroupistEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                senderidArr.push({
                    value:'All Contacts',
                    label:'All Contacts'
                })
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].description,
                        label:response.data.message[x].description
                    })
                }
                setLoadingGroup(false);
                setGroups(senderidArr)
            });
        }catch(err){
            setLoadingGroup(false);
            console.log(err)
        }
    }

    const contactListFunc = (desc) => {
        // setLoadingContact(true)
        setGroup(desc)
        setGroupTemp(desc.value)
        setLoading(true)
        setContactList([])
        try{
            ContactService.getContactList(desc.value).then((response) => {
             
             setLoading(false);
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                let groupArr=[];
                // for(let y=0;y<=response.data.message[x].group_contact_list.length-1;y++){
                //     groupArr.push(response.data.message[x].group_contact_list[y].description)
                // }
                // const groupStr = groupArr.join(',');

                // let senderidArr=[];
                // for(let y=0;y<=response.data.message[x].senderid_contact_list.length-1;y++){
                //     senderidArr.push(response.data.message[x].senderid_contact_list[y].sender_id)
                // }
                // const senderidStr = senderidArr.join(',');

                let status;
                if(response.data.message[x].status == 1){
                    status='Active';
                }
                else{
                    status='Disabled';
                }

                contactArr.push({
                    id:response.data.message[x].id,
                    name:response.data.message[x].name,
                    phone:response.data.message[x].phone,
                    subscribe:response.data.message[x].subscribe,
                    group:response.data.message[x].description,
                    senderid:response.data.message[x].sender_id,
                    status:status,
                })
             }
             setContactList(contactArr);
             
            //  setTimeout(() => {
            //     setLoadingContact(false)
            //     let table = new DataTable('#customer');
            //   }, 3000);
            });
        }catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const submitContact = () => {
        if(!name){
            return Swal.fire(
            'Error',
            'Name is required',
            'error'
            )
        }

        if(!phone){
            return Swal.fire(
            'Error',
            'Phone is required',
            'error'
            )
        }

        if(phone.length != 11){
            return Swal.fire(
            'Error',
            'Phone number must be 11 digits',
            'error'
            )
        }


        if(!group){
            return Swal.fire(
            'Error',
            'Group is required',
            'error'
            )
        }

        if(!to){
            return Swal.fire(
            'Error',
            'Sender ID is required',
            'error'
            )
        }


        setBackDrop(true)
        let postData
        postData = {
            name: name,
            phone: phone,
            subscribe: subscribe,
            group: group,
            sender_id:to
        }

        try{
            ContactService.addContact(postData).then((response) => {
                setBackDrop(false)

                if(response.data.status == 1){
                    return Swal.fire(
                        'Error',
                        response.data.message,
                        'error'
                        )
                }
                else{
                    setName('')
                    setPhone('')
                    setEmail('')
                    setSubscribe('default')
                    setBirthday('')
                    setGroup([])
                    setTo([])
                    return Swal.fire(
                        'Success',
                        'Contact added successfully',
                        'success'
                        )
                }
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setBackDrop(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setBackDrop(false)
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            submitContact()
        }
    }

    const handleUserListOption = () => {

    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }

    const editFunc = (id) => {
        setOpenEditForm(true)
        const editContent = (
            <>
            <EditContact contactID={id}/>
            </>
        )
        setEditFormContent(editContent)
    }

    const smsFunc = (id,num) => {
        setOpenSmsForm(true)
        const smsContent = (
            <>
            <SmsContact contactNumber={num}/>
            </>
        )
        setEditSmsContent(smsContent)
    }

    const deleteContactMultiFunc = () => {

        console.log(selectedContact)

        Swal.fire({
            title: 'Do you want to delete this selected contact permanently?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let arrkey;
                if(selectedContact.constructor === Array){
                    arrkey="array";
                }
                else{
                    arrkey="not array";
                }
                try{
                    ContactService.disableContact(selectedContact,arrkey).then((response) => {
                        setBackDrop(false)
                        let groupData;
                        if(groupTemp === 'All Contacts'){
                            groupData = {
                                value: 'All Contacts',
                                label: 'All Contacts'
                            }
                        }
                        else{
                            groupData = {
                                value: group,
                                label: group
                            }
                        }
                        setUpdateSelectedSenderIDBol(false)
                        setGroup(groupData)
                        contactListFunc(groupData)
                        return Swal.fire(
                            'Success',
                            'Successfully deleted contact',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })
    }

    const delFunc = (id,group) => {
        Swal.fire({
            title: 'Do you want to delete this contact permanently?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let postData={
                    id:id,
                    description:group
                }
                try{
                    ContactService.disableContact(postData,'not array').then((response) => {
                        setBackDrop(false)
                        let groupData;
                        if(groupTemp === 'All Contacts'){
                            groupData = {
                                value: 'All Contacts',
                                label: 'All Contacts'
                            }
                        }
                        else{
                            groupData = {
                                value: group,
                                label: group
                            }
                        }
                        setUpdateSelectedSenderIDBol(false)
                        setGroup(groupData)
                        contactListFunc(groupData)
                        return Swal.fire(
                            'Success',
                            'Successfully deleted contact',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const enableFunc = (id) => {
        Swal.fire({
            title: 'Do you want to enable this contact?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                try{
                    ContactService.enableContact(id).then((response) => {
                        setBackDrop(false)
        
                        return Swal.fire(
                            'Success',
                            'Successfully enabled contact',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const comingSoon = () => {
        setNoticSeverity('warning')
        setNoticeMSG('Coming Soon!')
        setOpenNotif(true)
    }

    const importContacts = (e) => {
        e.preventDefault();
        setLoadingContact(true);
        ContactService.importContact(file).then((response) => {
            setNoticSeverity('success')
            setNoticeMSG('Successfully Imported')
            setOpenNotif(true)
            setLoadingContact(false);
            let timerInterval;
            Swal.fire({
              title: "Successfully imported",
              icon: 'success',
              html: "Will refresh in <b></b>",
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
                window.location.reload()
              }
            });
        })
        .catch((error) => {
            // handle errors
            let data=[]
            for(var x=0;x<=error.response.data.errors.length-1;x++){
              console.log(error.response.data.errors[x][0]);
              data.push(error.response.data.errors[x][0]+', ')
            }
            setNoticSeverity('warning')
            setNoticeMSG(data)
            setOpenNotif(true)
            setLoadingContact(false);

          });
        ;

        // axios
        // .post("http://localhost:8000/api/import", formData, {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // })
        // .then((response) => {
        //   // handle the response
        //   setNoticSeverity('success')
        //   setNoticeMSG('Successfully Imported')
        //   setOpenNotif(true)
        // })
        // .catch((error) => {
        //   // handle errors
        //   let data=[]
        //   for(var x=0;x<=error.response.data.errors.length-1;x++){
        //     console.log(error.response.data.errors[x][0]);
        //     data.push(error.response.data.errors[x][0]+', ')
        //   }
        //   setNoticSeverity('warning')
        //   setNoticeMSG(data)
        //   setOpenNotif(true)
        // });

    }

    const handleFileChange = (e) => {
        if (e.target.files) {
        setFile(e.target.files[0]);
        }
    } ;

    const statusBodyTemplate = (contactlist) => {
        if(contactlist.subscribe == 1){
            return <Tag value={'Yes'} severity={getSeverity(contactlist)}></Tag>;
        }
        else{
            return <Tag value={'Stop'} severity={getSeverity(contactlist)}></Tag>;
        }

    };

    const getSeverity = (contactlist) => {
        switch (contactlist.subscribe) {
            case 1:
                return 'success';

            case 2:
                return 'warning';

            case 'Disabled':
                return 'danger';

            default:
                return null;
        }
    };

    const optionBodyTemplate = (contactlist) => {
        return <>
            <Tooltip title="Edit">
                <IconButton aria-label="Edit" onClick={() => editFunc(contactlist.id)} color="primary">
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Send Sms">
                <IconButton aria-label="Sms" onClick={() => smsFunc(contactlist.id,contactlist.phone)} color="success">
                    <SmsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete Contact">
                <IconButton aria-label="Sms" onClick={() => delFunc(contactlist.id,contactlist.group)} color="error">
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            {/* {contactlist.status == 1?
                <Tooltip title="Disable Contact">
                    <IconButton aria-label="Disable Contact" onClick={() => disableFunc(contactlist.id)} color="secondary">
                        <PersonAddDisabledIcon />
                    </IconButton>
                </Tooltip>
            :
                <Tooltip title="Enable Contact">
                    <IconButton aria-label="Enable Contact" onClick={() => enableFunc(contactlist.id)} color="secondary">
                        <PersonAddAltIcon />
                    </IconButton>
                </Tooltip>
            } */}
        </>


    }

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Contacts</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Contacts</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#list" data-toggle="tab">List</a></li>
                                            <li className="nav-item"><a className='nav-link' href="#import_contacts" data-toggle="tab">Import Contacts</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#" onClick={() => comingSoon()}>Download Excel/Csv Template for Import Contacts</a></li>
                                        </ul>
                                        
                                    </div>
                                    <div className="card-body">     
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name</label>
                                                                <input type="text" value={name} onChange={(e) => setName(e.target.value.toUpperCase())} required className="form-control" placeholder="Name" />
                                                            </div>           
                                                            <div className="form-group">
                                                                <label className="form-label">Phone</label>
                                                                <input type="text" maxLength={11} value={phone} onChange={(e) => setPhoneFunc(e.target.value)} required className="form-control" placeholder="Phone" />
                                                            </div>               
                                                            {/* <div className="form-group">
                                                                <label className="form-label">Email Address</label>
                                                                <input type="email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} required className="form-control" placeholder="Email" />
                                                            </div> */}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* <div className="form-group">
                                                                <label>Birthday</label><br/>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker 
                                                                    disableFuture={true} 
                                                                    value={birthday}
                                                                    onChange={(newValue) => setBirthday(newValue)}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div> */}
                                                            <div className="form-group">
                                                                <label className="form-label">Subscribe</label>
                                                                <select className='form-control' value={subscribe} onChange={(e) => setSubscribe(e.target.value)}>
                                                                    <option selected disabled value="default">Select Subscribe</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                            <div className='form-group'>
                                                                <label className="form-label">Sender ID</label>      
                                                                {loadingSenderid?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                <>
                                                                    <Select
                                                                        value={to}
                                                                        onChange={(e) => setTo(e)} 
                                                                        options={contacts}
                                                                        isMulti
                                                                        classNamePrefix="select"
                                                                    />   
                                                                </>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Group</label>
                                                                {loadingGroup?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                <>
                                                                    <Select
                                                                        value={group}
                                                                        onChange={(e) => setGroup(e)} 
                                                                        isMulti
                                                                        options={groups}
                                                                        classNamePrefix="select"
                                                                    />
                                                                </>
                                                                }

                                                            </div>  
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => submitContact()} className="btn btn-success" style={{float: 'right'}}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="list">
                                                <div className='col-md-3'>
                                                <Select
                                                    value={group}
                                                    onChange={(e) => contactListFunc(e)} 
                                                    options={groups}
                                                    classNamePrefix="select"
                                                />
                                                </div>
                                                {loadingContact?
                                                    <LinearProgress color="inherit" />
                                                :
                                                    <>
                                                        <DataTable value={contactlist}  sortMode="multiple" filters={filters} filterDisplay="row" paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]}  tableStyle={{ minWidth: '50rem' }}v
                                                         loading={loading}
                                                         selection={selectedContact}
                                                         selectionPageOnly
                                                         selectionMode={'checkbox'}
                                                         onSelectionChange={(e) => selectedContactList(e)}
                                                         paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                         currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                                                            <Column selectionMode="multiple" headerStyle={{ width: '3rem',  }}></Column>
                                                            <Column field="name" sortable filter filterPlaceholder="Search by name"  header="Name"  style={{ padding: '0' }}></Column>
                                                            <Column field="phone" sortable filter filterPlaceholder="Search by phone"  header="Phone"  style={{ padding: '0' }}></Column>
                                                            <Column field="group" sortable  filter filterPlaceholder="Search by group" header="Group"  style={{ padding: '0' }}></Column>
                                                            <Column field="senderid" sortable filter filterPlaceholder="Search by sender id" header="Sender ID"  style={{ padding: '0' }}></Column>
                                                            <Column body={statusBodyTemplate}  header="Subscribe"  style={{ width: '10%', padding: '0' }}></Column>
                                                            <Column body={optionBodyTemplate} header="Options" style={{ width: '15%', padding: '0' }}></Column>
                                                        </DataTable>
                                                        {updateSelectedSenderIDBol === true?
                                                        <>
                                                        <Button label="Delete Selected Contact" onClick={() => deleteContactMultiFunc()}  severity="danger" />
                                                        </>
                                                            :[]
                                                        }
                                                    </>
                                                }         

                                                <div className="table-responsive">
                                                    
                                                    {/* <table id="customer" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Name</th>
                                                                <th>Phone</th>
                                                                <th>Sender ID</th>
                                                                <th>Group</th>
                                                                <th>Status</th>
                                                                <th>Option</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {loadingContact?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                    <>
                                                                    {contactlist.map((option, index) => (
                                                                    <tr onDoubleClick={(e) => handleUserListOption(option.id)}>
                                                                            <td>{option.count}</td>
                                                                            <td>{option.name}</td>
                                                                            <td>{option.phone}</td>
                                                                            <td>{option.senderid}</td>
                                                                            <td>{option.group}</td>
                                                                            {option.status == 1?
                                                                                <td><span className='badge bg-success'>Active</span></td>
                                                                            :
                                                                                <td><span className='badge bg-danger'>Disabled</span></td>
                                                                            }
                                                                            <td>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton aria-label="Edit" onClick={() => editFunc(option.id)} color="primary">
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Send Sms">
                                                                                <IconButton aria-label="Sms" onClick={() => smsFunc(option.id,option.phone)} color="success">
                                                                                    <SmsIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {option.status == 1?
                                                                                <Tooltip title="Disable Contact">
                                                                                    <IconButton aria-label="Disable Contact" onClick={() => disableFunc(option.id)} color="secondary">
                                                                                        <PersonAddDisabledIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            :
                                                                                <Tooltip title="Enable Contact">
                                                                                    <IconButton aria-label="Enable Contact" onClick={() => enableFunc(option.id)} color="secondary">
                                                                                        <PersonAddAltIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                            </td>
                                                                    </tr>
                                                                    ))}
                                                                    </>
                                                                }                                            
                                                        </tbody>
                                                    </table> */}
                                                </div>
                                                <Modal
                                                open={openEditForm}
                                                onClose={handleEditForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editFormContent}
                                                    <button type="button" onClick={() => setOpenEditForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>

                                                <Modal
                                                open={openSmsForm}
                                                onClose={handleSmsForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editSmsContent}
                                                    <button type="button" onClick={() => setOpenSmsForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>

                                                <Modal
                                                open={openUpdateSelectedSenderID}
                                                onClose={handleUpdateSelectedSenderID}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editFormSenderIDContent}
                                                    <button type="button" onClick={() => setOpenUpdateSelectedSenderID(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>
                                            </div>
                                            <div className='tab-pane' id="import_contacts">
                                                <form onSubmit={(e) => importContacts(e)} method="POST" enctype="multipart/form-data">
                                                    <div class="form-group mb-4">
                                                        <div class="custom-file text-left">
                                                            <input type="file" name="file" onChange={handleFileChange} class="custom-file-input" id="customFile" />
                                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                                        </div>
                                                    </div>
                                                    {loadingContact?
                                                        <LinearProgress color="inherit" />
                                                    :
                                                    <>
                                                        {file.name}
                                                        <button type="submit" class="btn btn-success">Import </button>
                                                    </>
                                                    }
                                                </form>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default Contacts;