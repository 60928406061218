import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import SmsService from '../../api/SmsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from 'react-select';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CreatableSelect from 'react-select/creatable';
import CheckIcon from '@mui/icons-material/Check';
import ContactService from '../../api/ContactService';
import moment from 'moment'
import axios from 'axios'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import Button from '@mui/material/Button';
import ReportsService from '../../api/ReportsService';
import Tooltip from '@mui/material/Tooltip';
import Test from './Test';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Campaign = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [userList,setUserList] = React.useState([])
    const [contacts,setContacts] = React.useState([])
    const [groups,setGroups] = React.useState([])
    const [group,setGroup] = React.useState([])
    const [to,setTo] = React.useState([])
    const [msg,setMsg] = React.useState(null)
    const [loadingCust,setLoadingCust] = React.useState(false)
    const [countChar, setCountChar] = React.useState(420)
    const [alertMSG,setAlertMSG] = React.useState(null)
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const[scheduleDate,setScheduleDate] = React.useState(null)
    const [visible, setVisible] = useState(false);
    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);
    const [counter, setCounter] = useState(0);

    const [openNotif, setOpenNotif] = React.useState(false);
    const [openC, setOpenC] = React.useState(false);
    const [template, setTemplate] = React.useState([]);
    const [loadingTemplate,setLoadingTemplate] = React.useState(false);
    const [ApiTemplate, setApiTemplate] = React.useState([]);
    const [loadingApiTemplate,setLoadingApiTemplate] = React.useState(false);
    const [selectedApi,setSelectedApi] = React.useState('');
    const [countLoad,setCountLoad] = React.useState('');
    const [progress, setProgress] = useState(0);
    const [lengthLoop,setLengthLoop] = React.useState(0);
  
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        getGroup()
        smsTemplateListFunc()
        apiTemplateListFunc()
    }, []);

    const apiTemplateListFunc = () => {
        setLoadingApiTemplate(true)
        try{
            SmsService.apiTemplateList().then((response) => {
             setLoadingApiTemplate(false)
             console.log(response.data.message);
            
             let templateArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                templateArr.push({
                    value:response.data.message[x].api,
                    label:response.data.message[x].name,
                })
             }
             setApiTemplate(templateArr);
            });
        }catch(err){
            setLoadingApiTemplate(false);
            console.log(err)
        }
    }

    const smsTemplateListFunc = () => {
        setLoadingTemplate(true)
        try{
            SmsService.smsTemplateList().then((response) => {
             setLoadingTemplate(false)
             console.log(response.data.message);
            
             let templateArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                templateArr.push({
                    value:response.data.message[x].title,
                    label:response.data.message[x].template,
                })
             }
             setTemplate(templateArr);
            });
        }catch(err){
            setLoadingTemplate(false);
            console.log(err)
        }
    }

    const handleCloseC = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenC(false);
    };

    const getGroup = () => {
        setLoadingCust(true);
        try{
            ContactService.getGroupistEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].description,
                        label:response.data.message[x].description
                    })
                }
                setLoadingCust(false);
                setGroups(senderidArr)
            });
        }catch(err){
            setLoadingCust(false);
            console.log(err)
        }
    }

    const toast = React.useRef(null);

    const accept = (fromPhone,count) => {
        setCounter(prevCount => prevCount + 1)
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Sms Delivered to '+fromPhone, sticky: true });
    };

    const reject = (fromPhone,contact_id,errortype,errordesc) => {
        if(errortype == 'Number opted out.'){
            stopList(fromPhone,contact_id)
            toast.current.show({ severity: 'warn', summary: errortype, detail: errordesc+' - '+fromPhone, sticky: true });
            
        }
        else{
            toast.current.show({ severity: 'error', summary: errortype, detail: errordesc, sticky: true });
        }
    };

    const stopList = (tophone,contact_id) => {
        let postData={
            contact_id:contact_id,
            toPhone: tophone[0],
            group: group[0].value,
            integration: selectedApi
        }

        try{
            SmsService.unsubscribe(postData).then((response) => {
                console.log(response.data.message);

            });
        }catch(err){
            setLoadingCust(false);
            console.log(err)
        }
    }

    const clear = () => {
        toast.current.clear();
    };

    const loadingCount = (count) => {
        setCountLoad(count)
    }

    const sendSms = async () => {
        if(group == ""){
            return Swal.fire(
            'Error',
            'Group is required',
            'error'
            )
        }
        if(!selectedApi){
            return Swal.fire(
            'Error',
            'Api Key is required',
            'error'
            )
        }
        if(!msg){
            return Swal.fire(
            'Error',
            'Message is required',
            'error'
            )
        }

        setBackDrop(true)


        let postData
        postData = {
            group:group,
        }

        try{
            const response = await SmsService.sendSmsBulk(postData);
                
            setTo('')
            setMsg('')
            const headers = {
                // 'Content-Type': 'application/json',
                'Content-Type': 'application/json; charset=utf8; text/html;',
                'Authorization': selectedApi
            }

            for(let x=0;x<=response.data.groups.length-1;x++){
                let date_format;
                let data=[];
                let customermessageID='local';
                if(scheduleDate == null){
                    let date_text = dayjs().toLocaleString('en-US', {
                        timeZone: 'America/New_York',
                        });
    
                    let date_sched = moment(date_text).format("YYYY-MM-DDTHH:mm:ssZ");
                    let result = date_sched.substr(20, 5);
                    let lastDate = result.replace(":", "");

                    date_format = moment(date_text).format("YYYY-MM-DDTHH:mm:ss") + "+" +lastDate;

                    data.push({
                        content:msg.replace("{{name}}", response.data.groups[x].name),
                        to: response.data.groups[x].phone,
                        from: response.data.groups[x].sender_id,
                        contact_id: response.data.groups[x].contact_id,
                        phone: response.data.groups[x].orig_phone,
                        total: response.data.groups[x].total,
                        clientMessageId: customermessageID
                    })
                }
                else{
                    let date_text = scheduleDate.toLocaleString('en-US', {
                        timeZone: 'America/New_York',
                        });
    
                    let date_sched = moment(date_text).format("YYYY-MM-DDTHH:mm:ssZ");
                    let result = date_sched.substr(20, 5);
                    let lastDate = result.replace(":", "");

                    date_format = moment(date_text).format("YYYY-MM-DDTHH:mm:ss") + "+" +lastDate;

                    data.push({
                        content:msg.replace("{{name}}", response.data.groups[x].name),
                        to: response.data.groups[x].phone,
                        from: response.data.groups[x].sender_id,
                        contact_id: response.data.groups[x].contact_id,
                        phone: response.data.groups[x].orig_phone,
                        scheduledDeliveryTime: date_format,
                        total: response.data.groups[x].total,
                        clientMessageId: customermessageID
                    })
                }

                let counts=1;
                let loadCount=0;
                
                for(let x=0;x<=data.length-1;x++){
                    console.log(data[x].total);
               
                    axios.post('https://platform.clickatell.com/messages', data[x], {
                        headers: headers
                    })
                    .then(async(response) => {
                        console.log(response.data.responseCode)
                        
                        if(response.data.responseCode == 202){
                            let countLength=0;
                            for(let y=0;y<=response.data.messages.length-1;y++){
                                let data_reports = {
                                    id:counts++,
                                    sender_id: data[x].from,
                                    phone: data[x].to,
                                    msg_content: data[x].content,
                                    message_id: response.data.messages[y].apiMessageId,
                                    //2023-09-22T11:00:00+0200
                                    scheduled_date: date_format
                                }
                                setLengthLoop(response.data.messages.length)
                            
                                try{
                                    ReportsService.createDeliveryReports(data_reports).then(async(responses) => {
                                        accept(data[x].to,data.length)
                                        await new Promise(resolve => {
                                            console.log(resolve)
                                            setTimeout(resolve, 500)
                                        }); 
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                }catch(err){
                                    console.log(err)
                                }

                            } 
                            loadCount++;
                            
                            
                        }

                    })
                    .catch((error) => {
                        
                        if(typeof error.response.data.messages[0] === "undefined"){
                            reject(data[x].to,data[x].contact_id,error.response.data.error,error.response.data.errorDescription)
                        }
                        else{
                            reject(data[x].to,data[x].contact_id,error.response.data.messages[0].error,error.response.data.messages[0].errorDescription)
                        }
                    })
                }

            }

            

        }catch(err){
            console.log(err)
        }
        finally{
            setBackDrop(false);
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            sendSms()
        }
    }

    const setToFunc = (numb) => {
        setTo(numb)
        // if(typeof numb !== 'number' && isNaN(numb)){
        //     setTo('')
        // }
        // else{
        //     setTo(numb)
        // }
        
    }   

    const setMsgFunc = (message) => {
        setMsg(message)
        let countCharTotal = 420-message.length;
        setCountChar(countCharTotal)
        // var format = /[`!@#$%^&*()_+\-=\[\]{};'"\\|<>\/?~]/;
        // if(format.test(message) == true){
        //     setAlertMSG('Special Characters are Not Allowed')
        //     setOpenC(true)
        //     // setMsg('')
        //     // setCountChar(420)
        // }
        // else{
        //     setMsg(message)
        //     let countCharTotal = 420-message.length;
        //     setCountChar(countCharTotal)
        // }

    }

    const mergeTags = [
        {
            name: "Name",
            value: "%name%",
            sample: "John"
        }
    ]

    const addSmsTemplate = (label) => {
        setMsg(label)
        setCountChar(420-label.length)
    }

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Create Campaign</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Create Campaign</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create Campaign</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">     
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Group</label>
                                                                {loadingCust?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                <>
                                                                    <Select
                                                                        value={group}
                                                                        onChange={(e) => setGroup(e)} 
                                                                        isMulti
                                                                        options={groups}
                                                                        classNamePrefix="select"
                                                                    />
                                                                </>
                                                                }
                                                            </div>    
                                                            <div className="form-group">
                                                                <label>Schedule</label><br/>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker 
                                                                    disablePast={false} 
                                                                    value={scheduleDate}
                                                                    onChange={(newValue) => setScheduleDate(newValue)}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            {`Progress: ${progress.toFixed(2)}%`}
                                                            <div className="form-group">
                                                                <label>Api Key</label><br/>
                                                                {loadingApiTemplate?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                <>
                                                                    <select className='form-control' onChange={(e) => setSelectedApi(e.target.value)}>
                                                                        <option selected disabled>Select APi Key</option>
                                                                        {ApiTemplate.map((option, index) => (
                                                                            <>
                                                                                <option value={option.value}>{option.label} - {option.value}</option>
                                                                            </>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label>SMS Template</label><br/>
                                                                {loadingTemplate?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                <>
                                                                    <select className='form-control' onChange={(e) => addSmsTemplate(e.target.value)}>
                                                                        <option selected disabled>Select SMS Template</option>
                                                                        {template.map((option, index) => (
                                                                            <>
                                                                                <option value={option.label}>{option.value}</option>
                                                                            </>
                                                                        ))}
                                                                    </select>
                                                                </>
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Message</label>{counter}
                                                                <textarea max maxLength={420} value={msg} style={{height: '250px'}} onChange={(e) => setMsgFunc(e.target.value)} className='form-control'></textarea>
                                                                <i>{countChar} Characters left</i>
                                                            </div>       
                                                            {/* <Test/> */}
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => sendSms()} style={{float: 'right'}} className="btn btn-success">Send</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Toast ref={toast} />
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openC} autoHideDuration={6000} onClose={handleCloseC}>
                                    <Alert onClose={handleCloseC} severity="warning" sx={{ width: '100%' }}>
                                    {alertMSG}
                                    </Alert>
                                </Snackbar>

                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default Campaign;