import React, {useEffect} from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import HostConnect from './HostConnect';

const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";
const submitLogin = (email,password) => {
    return axios.post(API_URL + "auth/login", {
        email: email,
        password: password
    })
}

const LoginService = {
    submitLogin
}

export default LoginService;