import React, {useEffect} from 'react';
import axios from 'axios';
import HostConnect from './HostConnect';

const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";

const getUserList = () => {
  return axios.post(API_URL + "user/view/all");
};

const sendSms = (data) => {
  return axios.post(API_URL + "sms/send", {
    data: data,
  })
}

const sendSmsBulk = (data) => {
  return axios.post(API_URL + "sms/send-bulk", {
    data: data,
  })
}

const createSmsTemplate = (data) => {
  return axios.post(API_URL + "sms/create-sms-template", {
    data: data,
  })
}

const updateSmsTemplate = (data) => {
  return axios.post(API_URL + "sms/update-sms-template", {
    data: data,
  })
}

const smsTemplateList = () => {
  return axios.get(API_URL + "sms/sms-template-list");
};

const createApiTemplate = (data) => {
  return axios.post(API_URL + "sms/create-api-template", {
    data: data,
  })
}

const updateApiTemplate = (data) => {
  return axios.post(API_URL + "sms/update-api-template", {
    data: data,
  })
}

const apiTemplateList = () => {
  return axios.get(API_URL + "sms/api-template-list");
};

const deleteApiTemplate = (data) => {
  return axios.post(API_URL + "sms/delete-api-template", {
    data: data,
  })
}

const deleteSmsTemplate = (data) => {
  return axios.post(API_URL + "sms/delete-sms-template", {
    data: data,
  })
}

const sendSmsBulkTest = (data) => {
  return axios.post(API_URL + "sms/send-bulk", {
    data: data,
  })
}
const getReply = (data,count) => {
  return axios.post(API_URL + "clickatell/get-report", {
    data: data,
    count: count,
  })
}
const unsubscribe = (data) => {
  return axios.post(API_URL + "sms/unsubscribe", {
    data: data,
  })
}
const getStop = (data,count) => {
  return axios.post(API_URL + "sms/stop-list", {
    data: data,
    count: count,
  })
}

const UserService = {
    sendSms,
    getUserList,
    sendSmsBulk,
    createSmsTemplate,
    updateSmsTemplate,
    smsTemplateList,
    createApiTemplate,
    updateApiTemplate,
    apiTemplateList,
    deleteSmsTemplate,
    deleteApiTemplate,
    sendSmsBulkTest,
    getReply,
    unsubscribe,
    getStop
}
export default UserService;