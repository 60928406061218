import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import SmsService from '../../api/SmsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Tooltip from '@mui/material/Tooltip';
import { Button } from 'primereact/button';
import CheckIcon from '@mui/icons-material/Check';
import { Dialog } from 'primereact/dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import EditSmsTemplate from './EditSmsTemplate'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import 'primeicons/primeicons.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const SmsTemplate = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [title,setTitle] = React.useState(null)
    const [template,setTemplate] = React.useState('')
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);
    const [countChar, setCountChar] = React.useState(420)

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)
    const [visible, setVisible] = useState(false);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);

    const [openNotif, setOpenNotif] = React.useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        template: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        smsTemplateListFunc()
    }, []);

    const smsTemplateListFunc = () => {
        setLoadingContact(true)
        try{
            SmsService.smsTemplateList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    id:response.data.message[x].id,
                    title:response.data.message[x].title,
                    template:response.data.message[x].template,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const contactListFunc = () => {
        setLoadingContact(true)
        try{
            ContactService.getGroupList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    id:response.data.message[x].id,
                    name:response.data.message[x].description,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const submit = () => {
        if(!title){
            return Swal.fire(
            'Error',
            'Title is required',
            'error'
            )
        }

        if(!template){
            return Swal.fire(
            'Error',
            'Template is required',
            'error'
            )
        }

        setBackDrop(true)
        let postData
        
        let retemplate;
        if(Array.isArray(template) === true){
            retemplate=template[0]
        }
        else{
            retemplate=template
        }

        postData = {
            title: title,
            template: retemplate,
        }

        try{
            SmsService.createSmsTemplate(postData).then((response) => {
                setBackDrop(false)

                if(response.data.status == 1){
                    return Swal.fire(
                        'Error',
                        response.data.message,
                        'error'
                        )
                }
                else{
                    setTitle('')
                    setTemplate('')
                    smsTemplateListFunc()
                    setCountChar(420)
                    return Swal.fire(
                        'Success',
                        'Sms Template added successfully',
                        'success'
                        )
                }
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setBackDrop(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setBackDrop(false)
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            submit()
        }
    }

    const handleUserListOption = () => {

    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }


    const disableFunc = (id) => {
        Swal.fire({
            title: 'Do you want to disable this group?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                try{
                    ContactService.disableGroup(id).then((response) => {
                        setBackDrop(false)
        
                        contactListFunc()
                        return Swal.fire(
                            'Success',
                            'Successfully disabled group',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const enableFunc = (id) => {
        Swal.fire({
            title: 'Do you want to enable this group?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                try{
                    ContactService.enableGroup(id).then((response) => {
                        setBackDrop(false)
        
                        contactListFunc()
                        return Swal.fire(
                            'Success',
                            'Successfully enabled group',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const addMergeTag = (event) => {
        setVisible(false)
        var targ = event.target || event.srcElement;
        let temp = '{{'+targ.textContent+'}}' || '{{'+targ.innerText+'}}';
        console.log(temp)
        setTemplate(template => [template + temp.substring(0)])
    }

    const setMsgFunc = (message) => {
        setTemplate(message)
        let countCharTotal = 420-message.length;
        setCountChar(countCharTotal)
        // var format = /[`!@#$%^&*()_+\-=\[\]{};'"\\|<>\/?~]/;
        // if(format.test(message) == true){
        //     setAlertMSG('Special Characters are Not Allowed')
        //     setOpenC(true)
        //     // setMsg('')
        //     // setCountChar(420)
        // }
        // else{
        //     setMsg(message)
        //     let countCharTotal = 420-message.length;
        //     setCountChar(countCharTotal)
        // }

    }

    const editFunc = (id,title,template) => {
        setOpenEditForm(true)
        const editContent = (
            <>
            <EditSmsTemplate contactID={id} title={title} template={template}/>
            </>
        )
        setEditFormContent(editContent)
    }

    const deleteFunc = (id) => {
        Swal.fire({
            title: "Are you sure to delete this template?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
                try{
                    SmsService.deleteSmsTemplate(id).then((response) => {
                        let timerInterval;
                        Swal.fire({
                          title: "Successfully deleted",
                          icon: 'success',
                          html: "Will refresh in <b></b>",
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: () => {
                            Swal.showLoading();
                            const timer = Swal.getPopup().querySelector("b");
                            timerInterval = setInterval(() => {
                              timer.textContent = `${Swal.getTimerLeft()}`;
                            }, 100);
                          },
                          willClose: () => {
                            clearInterval(timerInterval);
                          }
                        }).then((result) => {
                          /* Read more about handling dismissals below */
                          if (result.dismiss === Swal.DismissReason.timer) {
                            console.log("I was closed by the timer");
                            window.location.reload()
                          }
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setLoadingContact(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setLoadingContact(false)
                }
            }
          });
    }
    const actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-users" rounded circle outlined className="mr-2"  onClick={(e)=>editFunc(rowData.id,rowData.title,rowData.template)}/>
                &nbsp;
                <Button icon="pi pi-trash" rounded outlined severity="danger" className="mr-2" onClick={()=>deleteFunc(rowData.id)}/>
            </React.Fragment>
        );

};

const actionactiveBodyTemplate = (stat) => {
    let span;
    if(stat.status == 1){
        return span = <td><span className='badge bg-success'>Active</span></td>;
    }
    else{
        return span = <td><span className='badge bg-danger'>Disabled</span></td>;
    }
}
    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Sms Template</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Sms Template</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#list" data-toggle="tab">List</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">     
                                        <Dialog header="Available Merge Tag" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                            <a href="#"><span className='badge bg-success' onClick={(e) => addMergeTag(e)}>name</span></a>
                                        </Dialog>
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label">Title</label>
                                                                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required className="form-control" placeholder="Enter Title" />
                                                            </div>         
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label">Template</label> &nbsp;<a href="#" onClick={() => setVisible(true)} >Merge Tag</a>
                                                            <textarea className="form-control"  max maxLength={420}  id="template-sms" placeholder="Enter Sms Template" value={template} onChange={(e) => setMsgFunc(e.target.value)}>

                                                            </textarea>
                                                            <i>{countChar} Characters left</i>
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => submit()} className="btn btn-success" style={{float: 'right'}}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="list">
                                                <div className="table-responsive">
                                                    {/* <table id="customer" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Title</th>
                                                                <th>Template</th>
                                                                <th>Status</th>
                                                                <th>Option</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {loadingContact?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                    <>
                                                                    {contactlist.map((option, index) => (
                                                                    <tr onDoubleClick={(e) => handleUserListOption(option.id)}>
                                                                            <td>{option.count}</td>
                                                                            <td>{option.title}</td>
                                                                            <td>{option.template}</td>
                                                                            {option.status == 1?
                                                                                <td><span className='badge bg-success'>Active</span></td>
                                                                            :
                                                                                <td><span className='badge bg-danger'>Disabled</span></td>
                                                                            }
                                                                            <td>
                                                                                <Tooltip title="Edit">
                                                                                    <IconButton aria-label="Edit" onClick={() => editFunc(option.id,option.title,option.template)} color="primary">
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton aria-label="Delete" onClick={() => deleteFunc(option.id)} color="primary">
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </td>
                                                                    </tr>
                                                                    ))}
                                                                    </>
                                                                }                                            
                                                        </tbody>
                                                    </table> */}

                                                        <div className="card">
                                                            <DataTable value={contactlist}  paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} 
                                                                    sortMode="multiple" filters={filters}
                                                                    filterDisplay="row"
                                                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                                    currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                                                    tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field="count" header="No." style={{ width: '10%' }}></Column>
                                                                <Column sortable filter field="title" header="Title" filterPlaceholder="Search by Title" style={{width: '15%'}}></Column>
                                                                <Column sortable filter field="template" header="Template" filterPlaceholder="Search by Template" style={{ width: '50%'}}></Column>
                                                                <Column field="status" body={actionactiveBodyTemplate} header="Status"></Column>
                                                                {/* <Column  filed="id" style={{ width: '25%' }}></Column> */}
                                                                <Column  body={actionBodyTemplate}  header="Option" style={{ minWidth: '10rem'}}></Column>
                                                            </DataTable>
                                                        </div>
                                                </div>
                                                <Modal
                                                open={openEditForm}
                                                onClose={handleEditForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editFormContent}
                                                    <button type="button" onClick={() => setOpenEditForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>

                                                <Modal
                                                open={openSmsForm}
                                                onClose={handleSmsForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editSmsContent}
                                                    <button type="button" onClick={() => setOpenSmsForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default SmsTemplate;