import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import LoginService from '../api/LoginService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
    const [email,setEmail] = React.useState(null);
    const [password,setPassword] = React.useState(null);
    const [loading,setLoading] = React.useState(null);
    const [backDrop,setBackDrop] = React.useState(false);

    const loginFunc = () => {

        setBackDrop(true)
        if(!email){
            setBackDrop(false)
           return Swal.fire('Error', 'Email is required', 'error')
        }
        if(!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
            setBackDrop(false)
            return Swal.fire('Error', 'Invalid Email Address', 'error')
        }
        if(!password){
            setBackDrop(false)
            return Swal.fire('Error', 'Password is required', 'error')
        }

        try {
            LoginService.submitLogin(email,password).then((response) => {
                console.log(response.data.user)
                localStorage.setItem("user_info", JSON.stringify(response.data.user));
                setBackDrop(false)
                window.location.href="/"
            })
            .catch(function (error) {
                setBackDrop(false)
                return Swal.fire('Error', error.response.data.message, 'error')
            })
        }catch(err){
            setBackDrop(false)
            return Swal.fire('Error', err, 'error')
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            loginFunc()
        }
    }

    return (
        <>
            <div className="card-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <form action="../../index3.html" method="post">
                    <div className="input-group mb-3">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => onPressEnter(e)} className="form-control" placeholder="Email" />
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                        </div>
                    </div>
                    </div>
                    <div className="input-group mb-3">
                    <input type="password" onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => onPressEnter(e)} className="form-control" placeholder="Password" />
                    <div className="input-group-append">
                        <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-8">
                        <div className="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember">
                            Remember Me
                        </label>
                        </div>
                    </div>
                    <div className="col-4">
                        <button type="button" onClick={() => loginFunc()} className="btn btn-primary btn-block">Sign In</button>
                    </div>
                    </div>
                </form>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backDrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}

export default Login;