import React, {useEffect} from 'react';
import axios from 'axios';
import HostConnect from './HostConnect';
const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";


const createDeliveryReports = (data) => {
  return axios.post(API_URL + "reports/delivery_reports_create", {
    data: data,
  })
};
const getDeliveryReportList = (data,count) => {
  return axios.post(API_URL + "reports/delivery_report_list", {
    data: data,
    count: count,
  })
}
const getReportsToday = () => {
  return axios.get(API_URL + 'reports/today_report');
}
const getReportsWeekly = () => {
  return axios.get(API_URL + 'reports/weekly_report');
}
const getReportsMonthly = () => {
  return axios.get(API_URL + 'reports/monthly_report');
}


const ReportsService = {
  createDeliveryReports,
  getDeliveryReportList,
  getReportsToday,
  getReportsWeekly,
  getReportsMonthly
}
export default ReportsService;