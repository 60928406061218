import React, {useEffect} from 'react';
import axios from 'axios';
import HostConnect from './HostConnect';
var Buffer = require('buffer/').Buffer

const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";

const play = (data) => {
    return axios.post(API_URL + "ai/tts-play-audio", {
      data: data,
    })
}

const textToSpeech = async (inputText) => {
  // Set the API key for ElevenLabs API. 
  // Do not use directly. Use environment variables.
  const API_KEY = '983efdf07e9267a3378352228051d5ca';
  // Set the ID of the voice to be used.
  const VOICE_ID = 'ZQe5CZNOzWyzPSCn5a3c';

  // Set options for the API request.
  const options = {
    method: 'POST',
    url: `https://api.elevenlabs.io/v1/text-to-speech/${VOICE_ID}`,
    headers: {
      accept: 'audio/mpeg', // Set the expected response type to audio/mpeg.
      'content-type': 'application/json', // Set the content type to application/json.
      'xi-api-key': `${API_KEY}`, // Set the API key in the headers.
    },
    data: {
      text: inputText, // Pass in the inputText as the text to be converted to speech.
      // voice_settings: {"similarity_boost":123,"stability":123,"style":123,"use_speaker_boost":true}
    },
    responseType: 'arraybuffer', // Set the responseType to arraybuffer to receive binary data as response.
  };

  // Send the API request using Axios and wait for the response.
  const speechDetails = await axios.request(options);

  // Return the binary audio data received from the API response.
  return speechDetails.data;
};

const create = async (title,text) => {
    const data2 = {
        model_id: "eleven_monolingual_v1",
        text: text,
        // voice_settings: {"similarity_boost":123,"stability":123,"style":123,"use_speaker_boost":true},
    };
    


    const data = await textToSpeech(text)
    // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
    const blob = new Blob([data], { type: 'audio/mpeg' });
    // Create a URL for the blob object
    // const url = URL.createObjectURL(blob);
    let buff = Buffer.from(data, 'binary').toString('base64')

    axios.post(API_URL + "ai/tts-create", {
      audio_code: buff,
      title:title,
      text:text
    }).then((response) => {
      if(response.status == 200){
        return 'Complete'
      }
      else{
        return 'Fail'
      }
    })
    .catch((error) => {
      console.log(error.response.data.message)
      return 'Fail'

  })
}

const update = (data) => {
    return axios.post(API_URL + "ai/tts-update", {
      data: data,
    })
}

const disable = (data) => {
    return axios.post(API_URL + "ai/tts-delete", {
      data: data,
    })
}

const list = (data) => {
  return axios.post(API_URL + "ai/tts-list", {
    data: data,
  })
}

const TTSService = {
    play,
    create,
    update,
    disable,
    list
}
export default TTSService;