let data;
if(localStorage.getItem('user_info') == null){
    data = null
}
else{

    data = localStorage.getItem('user_info')
}


const UserInfo = {
    data,
}
export default UserInfo;