import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Test = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const outerUrls = [
        'https://api.example.com/outer1',
        'https://api.example.com/outer2',
        'https://api.example.com/outer3',
        // Add more outer URLs as needed
      ];

      try {
        const nestedData = [];
        for (const outerUrl of outerUrls) {
          const outerResponse = await axios.get(outerUrl);
          const innerUrls1 = outerResponse.data.innerUrls1; // Assuming the outer response contains first set of inner URLs

          const innerData1 = [];
          for (const innerUrl1 of innerUrls1) {
            const innerResponse1 = await axios.get(innerUrl1);
            const innerUrls2 = innerResponse1.data.innerUrls2; // Assuming the first set of inner response contains second set of inner URLs

            const innerData2 = [];
            for (const innerUrl2 of innerUrls2) {
              const innerResponse2 = await axios.get(innerUrl2);
              innerData2.push(innerResponse2.data);
            }

            innerData1.push({
              innerData1: innerResponse1.data,
              innerData2,
            });
          }

          nestedData.push({
            outerData: outerResponse.data,
            innerData: innerData1,
          });
        }

        setData(nestedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {data.length > 0 ? (
        data.map((item, index) => (
          <div key={index}>
            <h3>Outer Data {index + 1}:</h3>
            <pre>{JSON.stringify(item.outerData, null, 2)}</pre>
            <h4>Inner Data Level 1:</h4>
            {item.innerData.map((innerItem1, innerIndex1) => (
              <div key={innerIndex1}>
                <pre>{JSON.stringify(innerItem1.innerData1, null, 2)}</pre>
                <h5>Inner Data Level 2:</h5>
                {innerItem1.innerData2.map((innerItem2, innerIndex2) => (
                  <pre key={innerIndex2}>{JSON.stringify(innerItem2, null, 2)}</pre>
                ))}
              </div>
            ))}
          </div>
        ))
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Test;
