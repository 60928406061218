import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import EditSenderid from './EditSenderid';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import 'primeicons/primeicons.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const SenderID = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);

    const [openNotif, setOpenNotif] = React.useState(false);
  
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    

    useEffect(() => {
        contactListFunc()
    }, []);

    const contactListFunc = () => {
        setLoadingContact(true)
        try{
            ContactService.getSenderIDList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    id:response.data.message[x].id,
                    phone:response.data.message[x].phone,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const submitContact = () => {
        if(!phone){
            return Swal.fire(
            'Error',
            'Phone is required',
            'error'
            )
        }

        if(phone.length != 11){
            return Swal.fire(
            'Error',
            'Phone number must be 11 digits',
            'error'
            )
        }

        setBackDrop(true)
        let postData
        postData = {
            phone: phone,
        }

        try{
            ContactService.addSenderID(postData).then((response) => {
                setBackDrop(false)

                if(response.data.status == 1){
                    return Swal.fire(
                        'Error',
                        response.data.message,
                        'error'
                        )
                }
                else{
                    setPhone('')
                    contactListFunc()
                    return Swal.fire(
                        'Success',
                        'Successfully added Sender ID',
                        'success'
                        )
                }
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setBackDrop(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setBackDrop(false)
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            submitContact()
        }
    }

    const handleUserListOption = () => {

    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }


    const disableFunc = (id) => {
        Swal.fire({
            title: 'Do you want to disable this Sender ID?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                try{
                    ContactService.disableSenderID(id).then((response) => {
                        setBackDrop(false)
        
                        contactListFunc()
                        return Swal.fire(
                            'Success',
                            'Successfully disabled Sender ID',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const enableFunc = (id) => {
        Swal.fire({
            title: 'Do you want to enable this Sender ID?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                try{
                    ContactService.enableSenderID(id).then((response) => {
                        setBackDrop(false)
        
                        contactListFunc()
                        return Swal.fire(
                            'Success',
                            'Successfully enabled Sender ID',
                            'success'
                            )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })

    }

    const editFunc = (id) => {
        setOpenEditForm(true)
        const editContent = (
            <>
            <EditSenderid contactID={id}/>
            </>
        )
        setEditFormContent(editContent)
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-users" rounded circle outlined className="mr-2"  onClick={(e)=>editFunc(rowData.id)}/>
                &nbsp;
                <Button icon="pi pi-trash" rounded outlined severity="danger" className="mr-2" onClick={()=>disableFunc(rowData.id)}/>
            </React.Fragment>
        );

    };

    const actionactiveBodyTemplate = (stat) => {
        let span;
        if(stat.status == 1){
            return span = <td><span className='badge bg-success'>Active</span></td>;
        }
        else{
            return span = <td><span className='badge bg-danger'>Disabled</span></td>;
        }
    }
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Sender ID</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Sender ID</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#list" data-toggle="tab">List</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">     
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Sender Phone ID</label>
                                                                <input type="text" maxLength={11} value={phone} onChange={(e) => setPhoneFunc(e.target.value)} required className="form-control" placeholder="Phone" />
                                                            </div>            
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => submitContact()} className="btn btn-success" style={{float: 'right'}}>Add</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="list">
                                                <div className="table-responsive">
                                                    {/* <table id="customer" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Sender Phone ID</th>
                                                                <th>Status</th>
                                                                <th>Option</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {loadingContact?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                    <>
                                                                    {contactlist.map((option, index) => (
                                                                    <tr onDoubleClick={(e) => handleUserListOption(option.id)}>
                                                                            <td>{option.count}</td>
                                                                            <td>{option.phone}</td>
                                                                            {option.status == 1?
                                                                                <td><span className='badge bg-success'>Active</span></td>
                                                                            :
                                                                                <td><span className='badge bg-danger'>Disabled</span></td>
                                                                            }
                                                                            <td>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton aria-label="Edit" onClick={() => editFunc(option.id)} color="primary">
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {option.status == 1?
                                                                                <Tooltip title="Disable Sender ID">
                                                                                    <IconButton aria-label="Disable Contact" onClick={() => disableFunc(option.id)} color="secondary">
                                                                                        <PersonAddDisabledIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            :
                                                                                <Tooltip title="Enable Sender ID">
                                                                                    <IconButton aria-label="Enable Contact" onClick={() => enableFunc(option.id)} color="secondary">
                                                                                        <PersonAddAltIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                            </td>
                                                                    </tr>
                                                                    ))}
                                                                    </>
                                                                }                                            
                                                        </tbody>
                                                    </table> */}
                                                     <div className="card">
                                                            <DataTable value={contactlist}  paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                                                            sortMode="multiple" filters={filters}
                                                            filterDisplay="row"
                                                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                            currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                                            >
                                                                <Column field="count" header="No." style={{ width: '0%' }}></Column>
                                                                <Column sortable filter  field="phone" header="Sender Phone ID" filterPlaceholder="Search by Phone ID" style={{ width: '25%' }}></Column>
                                                                <Column  filed="status" body={actionactiveBodyTemplate} header="Status" style={{ width: '25%' }}></Column>
                                                                {/* <Column  filed="id" style={{ width: '25%' }}></Column> */}
                                                                <Column  body={actionBodyTemplate}  header="Option" style={{ minWidth: '15rem'}}></Column>
                                                            </DataTable>
                                                        </div>
                                                </div>
                                                <Modal
                                                open={openEditForm}
                                                onClose={handleEditForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editFormContent}
                                                    <button type="button" onClick={() => setOpenEditForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>

                                                <Modal
                                                open={openSmsForm}
                                                onClose={handleSmsForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editSmsContent}
                                                    <button type="button" onClick={() => setOpenSmsForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default SenderID;