import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import SmsService from '../../api/SmsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Select from 'react-select';

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SmsContact = (props) => {
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [idForm,setIdForm] = React.useState(null)
    const [alertMSG,setAlertMSG] = React.useState(null)
    const [contacts,setContacts] = React.useState([])
    const [groups,setGroups] = React.useState([])
    const [group,setGroup] = React.useState([])
    const [to,setTo] = React.useState(props.contactNumber)
    const [from,setFrom] = React.useState(null)
    const [msg,setMsg] = React.useState(null)
    const [countChar, setCountChar] = React.useState(420)

    const [openB, setOpenB] = React.useState(false);
    const [openC, setOpenC] = React.useState(false);

    const handleCloseB = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenB(false);
    };

    const handleCloseC = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenC(false);
    };

    useEffect(() => {
        contactListFunc()
        viewContactForm(props.contactID)
        getSenderID()
    }, []);

    const getSenderID = () => {
        try{
            ContactService.getSenderIDListEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].phone,
                        label:response.data.message[x].phone
                    })
                }
             
                setContacts(senderidArr)
            });
        }catch(err){
            console.log(err)
        }
    }

    const viewContactForm = (id) => {
        setLoadingContact(true)
        try{
            ContactService.viewEditForm(id).then((response) => {
             setLoadingContact(false)
             console.log(response.data.message.birthday);
             setName(response.data.message.name)
             setPhone(response.data.message.phone)
             setEmail(response.data.message.email)
             setBirthday(dayjs(response.data.message.birthday))
             setSubscribe(response.data.message.subscribe)
             setIdForm(response.data.message.id)
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const contactListFunc = () => {
        setLoadingContact(true)
        try{
            ContactService.getContactList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    name:response.data.message[x].name,
                    phone:response.data.message[x].phone,
                    email:response.data.message[x].email,
                    birthday:response.data.message[x].birthday,
                    subscribe:response.data.message[x].subscribe,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }

    const updateContact = () => {
        if(!name){
            return Swal.fire(
            'Error',
            'Name is required',
            'error'
            )
        }

        if(!phone){
            return Swal.fire(
            'Error',
            'Phone is required',
            'error'
            )
        }

        if(phone.length != 11){
            return Swal.fire(
            'Error',
            'Phone number must be 11 digits',
            'error'
            )
        }

        if(!birthday){
            return Swal.fire(
            'Error',
            'Birthday is required',
            'error'
            )
        }

        if(!subscribe){
            return Swal.fire(
            'Error',
            'Subscribe is required',
            'error'
            )
        }


        if(!email){
            return Swal.fire(
            'Error',
            'Email Address is required',
            'error'
            )
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(emailRegex.test(email) == false){
            return Swal.fire(
                'Error',
                'You have entered an invalid email address!',
                'error'
                )
        }

        setLoadingContact(true)
        let postData
        postData = {
            id:idForm,
            name: name,
            email: email,
            phone: phone,
            subscribe: subscribe,
            birthday: birthday,
        }

        try{
            ContactService.updateContactInfo(postData).then((response) => {
                setLoadingContact(false)
                contactListFunc()
                setAlertMSG('Contact Updated Successfully')
                setOpenB(true)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setLoadingContact(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setLoadingContact(false)
        }
    }

    const setToFunc = (numb) => {
        setTo(numb)
        // if(typeof numb !== 'number' && isNaN(numb)){
        //     setTo('')
        // }
        // else{
        //     setTo(numb)
        // }
        
    }   

    const setFromFunc = (numb) => {
        setFrom(numb)
        // if(typeof numb !== 'number' && isNaN(numb)){
        //     setTo('')
        // }
        // else{
        //     setTo(numb)
        // }
        
    } 

    const sendSms = () => {
        if(!from){
            setAlertMSG('Sender ID is required')
            setOpenC(true)
            return false
        }

        if(!msg){
            setAlertMSG('Message is required')
            setOpenC(true)
            return false
        }

        setLoadingContact(true)
        let postData
        postData = {
            to: to,
            from:from.value,
            msg: msg,
        }
        try{
            SmsService.sendSms(postData).then((response) => {
                setLoadingContact(false)
                setAlertMSG(response.data.message)
                setOpenB(true)
                setFrom('');
                setMsg('')
                setCountChar(420)
            })
            .catch((error) => {
                setLoadingContact(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setLoadingContact(false)
        }
    }

    const setMsgFunc = (message) => {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if(format.test(message) == true){
            setAlertMSG('Special Characters are Not Allowed')
            setOpenC(true)
            setMsg('')
            setCountChar(420)
        }
        else{
            setMsg(message)
            let countCharTotal = 420-message.length;
            setCountChar(countCharTotal)
        }

    }


    return (
        <>
                <h2>Send Sms</h2>
                <form className="form-horizontal" method="POST">
                    <div className="row">
                        <div className="col-md-6">
                            <div className='form-group'>
                                <label className="form-label">Sender ID</label>        
                                <Select
                                    value={from}
                                    onChange={(e) => setFromFunc(e)} 
                                    options={contacts}
                                    classNamePrefix="select"
                                />   
                            </div>
                            <div className='form-group'>
                                <label className="form-label">Recipient's Phone Number</label>        
                                <input className='form-control' disabled value={to} />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Message</label>
                                <textarea max maxLength={420} value={msg} onChange={(e) => setMsgFunc(e.target.value)} className='form-control'></textarea>
                                <i>{countChar} Characters left</i>
                            </div>                        
                        </div>
                    </div>   
                </form>
                {loadingContact == true?
                    <button disabled type="button" onClick={() => sendSms()} className="btn btn-success" style={{float: 'right'}}>Loading...</button>
                :
                    <button type="button" onClick={() => sendSms()} className="btn btn-success" style={{float: 'right'}}>Send Sms</button>   
                }
                <Snackbar open={openB} autoHideDuration={6000} onClose={handleCloseB}>
                    <Alert onClose={handleCloseB} severity="success" sx={{ width: '100%' }}>
                    {alertMSG}
                    </Alert>
                </Snackbar>
                <Snackbar open={openC} autoHideDuration={6000} onClose={handleCloseC}>
                    <Alert onClose={handleCloseC} severity="warning" sx={{ width: '100%' }}>
                    {alertMSG}
                    </Alert>
                </Snackbar>
        </>
    );
}

export default SmsContact;