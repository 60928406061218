import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Select from 'react-select';

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditContact = (props) => {
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [idForm,setIdForm] = React.useState(null)
    const [alertMSG,setAlertMSG] = React.useState(null)
    const [groups,setGroups] = React.useState([])
    const [group,setGroup] = React.useState([])
    const [contacts,setContacts] = React.useState([])
    const [to,setTo] = React.useState([])

    const [openB, setOpenB] = React.useState(false);


    const handleCloseB = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenB(false);
    };

    useEffect(() => {
        contactListFunc()
        viewContactForm(props.contactID)
        getGroup()
        getSenderID()
    }, []);

    const getSenderID = () => {
        try{
            ContactService.getSenderIDListEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].phone,
                        label:response.data.message[x].phone
                    })
                }
             
                setContacts(senderidArr)
            });
        }catch(err){
            console.log(err)
        }
    }

    const getGroup = () => {
        try{
            ContactService.getGroupistEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].description,
                        label:response.data.message[x].description
                    })
                }
             
                setGroups(senderidArr)
            });
        }catch(err){
            console.log(err)
        }
    }

    const viewContactForm = (id) => {
        setLoadingContact(true)
        try{
            ContactService.viewEditForm(id).then((response) => {
             setLoadingContact(false)
             console.log(response.data.message.birthday);
             setName(response.data.message.name)
             setPhone(response.data.message.phone)
             setEmail(response.data.message.email)
             setBirthday(dayjs(response.data.message.birthday))
             setSubscribe(response.data.message.subscribe)
             setIdForm(response.data.message.id)
             let groupArr=[];
             for(let y=0;y<=response.data.message.group_contact_list.length-1;y++){
                 groupArr.push({
                    value:response.data.message.group_contact_list[y].description,
                    label:response.data.message.group_contact_list[y].description
                 })
             }
             setGroup(groupArr)

             let senderidArr=[];
             for(let y=0;y<=response.data.message.senderid_contact_list.length-1;y++){
                    senderidArr.push({
                    value:response.data.message.senderid_contact_list[y].sender_id,
                    label:response.data.message.senderid_contact_list[y].sender_id
                 })
             }
             setTo(senderidArr)
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const contactListFunc = () => {
        setLoadingContact(true)
        try{
            ContactService.getContactList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    name:response.data.message[x].name,
                    phone:response.data.message[x].phone,
                    email:response.data.message[x].email,
                    birthday:response.data.message[x].birthday,
                    subscribe:response.data.message[x].subscribe,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }

    const updateContact = () => {
        if(!name){
            return Swal.fire(
            'Error',
            'Name is required',
            'error'
            )
        }

        if(!phone){
            return Swal.fire(
            'Error',
            'Phone is required',
            'error'
            )
        }

        if(phone.length != 11){
            return Swal.fire(
            'Error',
            'Phone number must be 11 digits',
            'error'
            )
        }

        if(!group){
            return Swal.fire(
            'Error',
            'Group is required',
            'error'
            )
        }

        if(!to){
            return Swal.fire(
            'Error',
            'Sender ID is required',
            'error'
            )
        }

        setLoadingContact(true)
        let postData
        postData = {
            id:idForm,
            name: name,
            email: email,
            phone: phone,
            subscribe: subscribe,
            birthday: birthday,
            group: group,
            sender_id:to
        }

        try{
            ContactService.updateContactInfo(postData).then((response) => {
                setLoadingContact(false)
                contactListFunc()
                setAlertMSG('Contact Updated Successfully')
                setOpenB(true)
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setLoadingContact(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setLoadingContact(false)
        }
    }

    return (
        <>
                <h2>Edit Contact</h2>
                {loadingContact == true?
                    <Box sx={{ display: 'flex' }}>
                        <Skeleton variant="rounded" width={'100%'} height={300} />
                    </Box>
                :
                    <form className="form-horizontal" method="POST">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <input type="text" value={name} onChange={(e) => setName(e.target.value.toUpperCase())} required className="form-control" placeholder="First Name" />
                                </div>           
                                <div className="form-group">
                                    <label className="form-label">Phone</label>
                                    <input type="text" maxLength={11} value={phone} onChange={(e) => setPhoneFunc(e.target.value)} required className="form-control" placeholder="Phone" />
                                </div>     
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label className="form-label">Sender ID</label>        
                                    <Select
                                        value={to}
                                        onChange={(e) => setTo(e)} 
                                        options={contacts}
                                        isMulti
                                        classNamePrefix="select"
                                    />   
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Group</label>
                                    <Select
                                        value={group}
                                        onChange={(e) => setGroup(e)} 
                                        isMulti
                                        options={groups}
                                        classNamePrefix="select"
                                    />
                                </div>  
                            </div>
                        </div>   
                    </form>
                }
                <button type="button" onClick={() => updateContact()} className="btn btn-success" style={{float: 'right'}}>Update</button>
                <Snackbar open={openB} autoHideDuration={6000} onClose={handleCloseB}>
                    <Alert onClose={handleCloseB} severity="success" sx={{ width: '100%' }}>
                    {alertMSG}
                    </Alert>
                </Snackbar>
        </>
    );
}

export default EditContact;