import React, { Component } from 'react'
import { Outlet, Link } from "react-router-dom";
// import test from './test.png';

const Header = () => {
  return (
      <div>
        <div className="login-page">
          <div className="login-box">
              <div className="card card-outline card-primary">
                  <div className="card-header text-center">
                      <a href="../../index2.html" className="h1">
                        {/* <img src={test} style={{width: '124px'}} /> */}
                        Telpro SMS Portal
                      </a>
                  </div>
                  <Outlet />
              </div>
          </div>
        </div>
      </div>

  )
}

export default Header