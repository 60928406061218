import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import SmsService from '../../api/SmsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import { Button } from 'primereact/button';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import moment from 'moment';

import { Calendar } from 'primereact/calendar';
import { ConstructionSharp } from '@mui/icons-material';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const ReplyReports = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [reportList,setReportList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [groups,setGroups] = React.useState([])
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);
    const [selectedContact, setSelectedContact] = useState(null);

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)
    const [updateSelectedSenderIDBol,setUpdateSelectedSenderIDBol] = React.useState(false);
    const [loadingGroup,setLoadingGroup] = React.useState(false)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [openNotif, setOpenNotif] = React.useState(false);
    const [date, setDate] = useState(null);
    const [dates, setDates] = useState(null);
    const [myArr,setMyArr] = useState([])
    const [loading,setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        toNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        fromNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        text: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        group: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        searchParam()
        getGroup()
    }, []);

    const replyReportList = (date,selection) => {
        console.log(date)
        
        if(selection == 'one'){
            moment(date).format("YYYY-MM-DD")
            setDate(date)
            setDates(null)
            setLoading(true)
            getReplyReport(moment(date).format("YYYY-MM-DD"))
        }
        else{
            moment(date).format("YYYY-MM-DD")
            setDates(date)
            setDate(null)
        }

    }

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    
    const getReplyReport = (data) => {
        let count;
        let formattedDateArray;
        if(data.constructor === Array){
            formattedDateArray = data.map(date => moment(date).format('YYYY-MM-DD'));
            console.log('array')
            console.log(data)
            count=2
        }
        else{
            formattedDateArray = data
            console.log('not array')
            count=1
        }
        try{
            SmsService.getReply(formattedDateArray,count).then((response) => {
             setLoadingContact(false)
             
             let contactArr=[];
             for( let x=0;x<=response.data.message.length-1;x++){
                let textFormat = response.data.message[x].text.replaceAll("%20"," ");
                let name;
                // if(response.data.message[x].contacts == null){
                //     name='N/A'
                // }
                // else{
                //     name=response.data.message[x].contacts.name
                // }
                let group_contact;
    

                if(response.data.message[x].contacts.length > 1){
                    name=response.data.message[x].contacts[1].name

                    for(let y=1;y<=response.data.message[x].contacts.length-1;y++){
                        if(response.data.message[x].contacts[y].group_contact_list !== null){
                            group_contact=response.data.message[x].contacts[y].group_contact_list.description
                        }
                        else{
                            group_contact='NA'
                        }
                    }

                    
                }
                else{
                    name=response.data.message[x].contacts[0].name
                    group_contact=response.data.message[x].contacts[0].group_contact_list.description
                }

                contactArr.push({
                    integrationName:response.data.message[x].integrationName,
                    name:name,
                    toNumber:response.data.message[x].toNumber,
                    text:textFormat.replaceAll("%2C"," "),
                    group:group_contact,
                    fromNumber:response.data.message[x].fromNumber,
                    messageId:response.data.message[x].messageId,
                    replyMessageId:response.data.message[x].replyMessageId,
                    timestamp:moment(response.data.message[x].created_at).format('LLL'),
                    replyClientMessageId:response.data.message[x].replyClientMessageId,
                })
             }
             setLoading(false)
             setMyArr(contactArr)
             console.log(contactArr)
            //  setReportList(contactArr);
            //  console.log(response.data.message)
             
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const submitDateRange = () => {
        console.log(dates[1])
        setLoading(true)
        if(dates[1] != null){
            getReplyReport(dates)
        }
        else{
            setLoading(false)
        }
    }

    const selectedContactList = (e) => {
        if(e.value.length > 0){
            setUpdateSelectedSenderIDBol(true)
        }
        else{
            setUpdateSelectedSenderIDBol(false)
        }
        setSelectedContact(e.value)
    }

    const searchParam = async() => {
        let url = new URLSearchParams(window.location.search);
        const query = url;
        const param = query.get('report');
        
        if(param == 'today'){
            setDate(new Date(moment().format('LL')))
            replyReportList(new Date(moment().format('LL')),'one')
        }
        if(param == 'weekly'){
            try{
                let sdate = new Date(moment().format('LL'));
                let fdate = new Date(moment().subtract(7, 'days').format('LL'))
                let mergeDate = [];
                mergeDate.push(fdate);
                mergeDate.push(sdate);
                setDates(mergeDate)
                setTimeout(() => {
                    document.getElementById('dateRange').click();
                },1000)
                
            }
            catch(err){
                console.log(err)
            }
        }
        if(param == 'monthly'){
            try{
                let sdate = new Date(moment().format('LL'));
                let fdate = new Date(moment().subtract(30, 'days').format('LL'))
                let mergeDate = [];
                mergeDate.push(fdate);
                mergeDate.push(sdate);
                setDates(mergeDate)
                setTimeout(() => {
                    document.getElementById('dateRange').click();
                },1000)
                
            }
            catch(err){
                console.log(err)
            }
        }
    }

    const unsubscribeContactMultiFunc = () => {

        console.log(selectedContact)

        Swal.fire({
            title: 'Do you want to Opt-Out this selected contact?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let arrkey;
                if(selectedContact.constructor === Array){
                    arrkey="array";
                }
                else{
                    arrkey="not array";
                }
                try{
                    ContactService.unsubscribeContact(selectedContact,arrkey).then((response) => {
                        setBackDrop(false)
                        // let groupData;
                        // if(groupTemp === 'All Contacts'){
                        //     groupData = {
                        //         value: 'All Contacts',
                        //         label: 'All Contacts'
                        //     }
                        // }
                        // else{
                        //     groupData = {
                        //         value: group,
                        //         label: group
                        //     }
                        // }
                        // setUpdateSelectedSenderIDBol(false)
                        // setGroup(groupData)
                        // contactListFunc(groupData)
                        // return Swal.fire(
                        //     'Success',
                        //     'Successfully deleted contact',
                        //     'success'
                        //     )
                    })
                    .catch((error) => {
                        console.log(error.response.data.message)
                        setBackDrop(false)
                        return Swal.fire(
                            'Error',
                            error.response.data.message,
                            'error'
                            )
                    })
                }catch(err){
                    console.log(err)
                    setBackDrop(false)
                }
            } else if (result.isDenied) {
            }
          })
    }

    
    const statusRowFilterTemplate = (options) => {
        return (
            <>
                {loadingGroup?
                    <LinearProgress color="inherit" />
                :
                <>
                    <Dropdown value={options.value} options={groups} onChange={(e) => options.filterApplyCallback(e.value)}  placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
                </>
                }
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.group} />;
    };

    const getGroup = () => {
        setLoadingGroup(true);
        try{
            ContactService.getGroupistEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].description,
                        label:response.data.message[x].description
                    })
                }
                setLoadingGroup(false);
                setGroups(senderidArr)
            });
        }catch(err){
            setLoadingGroup(false);
            console.log(err)
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Reply Reports</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Reply Reports</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">     
                                        <div className="table-responsive">
                                            <div className="card">
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                                <div className="card flex justify-content-center">
                                                                    <Calendar placeholder="Select Date" variant="filled" value={date} onChange={(e) => replyReportList(e.value,'one')} />

                                                                </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                                    <Calendar placeholder="Select Date Range" value={dates}  onChange={(e) => replyReportList(e.value,'range')} selectionMode="range" readOnlyInput hideOnRangeSelection />
                                                                    <Button label="Submit Date Range" id="dateRange" onClick={submitDateRange}/>
                                                    </div>
                                                </div>
                                                <DataTable 
                                                sortMode="multiple" filters={filters}
                                                filterDisplay="row" paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                selection={selectedContact}
                                                selectionPageOnly
                                                selectionMode={'checkbox'}
                                                onSelectionChange={(e) => selectedContactList(e)}
                                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                                loading={loading} value={myArr} tableStyle={{ minWidth: '50rem'}}  style={{ fontSize: '0.9rem' }}>
                                                    <Column selectionMode="multiple" headerStyle={{ width: '3rem',  }}></Column>
                                                    <Column field="timestamp" header="SMS Date"></Column>
                                                    <Column sortable field="integrationName" header="SMS Content"></Column>
                                                    <Column field="timestamp" header="Reply Date"></Column>
                                                    <Column sortable filter field="group" header="Group" filterPlaceholder="Search by Group"></Column>
                                                    <Column sortable filter field="fromNumber" header="To Number" filterPlaceholder="Search by To Number"></Column>
                                                    <Column sortable filter field="name" header="Contact Name" filterPlaceholder="Search by Name"></Column>
                                                    <Column sortable filter field="toNumber" header="From Number" filterPlaceholder="Search by From Number"></Column>
                                                    <Column sortable filter field="text" header="Message Content" filterPlaceholder="Search by Msg Content"></Column>
                                                    {/* <Column field="messageId" header="Message ID"></Column>
                                                    <Column field="replyMessageId" header="Reply ID"></Column> */}
                                                </DataTable>
                                                {updateSelectedSenderIDBol === true?
                                                <>
                                                <Button label="Unsubscribe Selected Contact" onClick={() => unsubscribeContactMultiFunc()}  severity="danger" />
                                                </>
                                                    :[]
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default ReplyReports;