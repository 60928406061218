import React, {useEffect} from 'react';
import axios from 'axios';
import HostConnect from './HostConnect';

const API_URL = HostConnect.API_HOST+"/"+HostConnect.API_ROOT+"/public/api/";
// const API_URL = HostConnect.API_HOST+"/api/";

const getUserList = () => {
  return axios.post(API_URL + "user/view/all");
};

const addUser = (data) => {
  return axios.post(API_URL + "user/add", {
    data: data,
  })
}

const UserService = {
    addUser,
    getUserList
}
export default UserService;