import Header from './Authentication/Header';
import Menu from './Authentication/Menu';
import Dashboard from './Authentication/Dashboard';
import Footer from './Authentication/Footer';

function LoginTemplate() {
  return (
    <div class="wrapper">
      <Header/>
    </div>
  );
}

export default LoginTemplate;
