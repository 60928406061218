import Header from './Homepage/Header';
import Menu from './Homepage/Menu';
import Dashboard from './Homepage/Dashboard';
import Footer from './Homepage/Footer';
import { Outlet, Link } from "react-router-dom";

function HomepageTemplate() {
  return (
    <div class="wrapper">
      <Header/>
      <Menu/>
      <Outlet />
      <Footer/>
    </div>
  );
}

export default HomepageTemplate;
