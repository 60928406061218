import  React from 'react';
import {useState,useEffect} from 'react';
import 'primeicons/primeicons.css';
import { Chart } from 'primereact/chart';
import ReportsService from '../../api/ReportsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

const Dashboard = () => {
    const [date, setDate] = useState(new Date());
    const [backDrop,setBackDrop] = React.useState(true);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [chartDataWeekly, setChartDataWeekly] = useState({});
    const [chartOptionsWeekly, setChartOptionsWeekly] = useState({});
    const [chartDataMonthly, setChartDataMonthly] = useState({});
    const [chartOptionsMonthly, setChartOptionsMonthly] = useState({});
    const [todaysDelivery,setTodaysDelivery] = useState(0);
    const [todaysReply,setTodaysReply] = useState(0);
    const [todaysStop,setTodaysStop] = useState(0);
    const [weeklyDelivery,setWeeklyDelivery] = useState(0);
    const [weeklyReply,setWeeklyReply] = useState(0);
    const [weeklyStop,setWeeklyStop] = useState(0);
    const [monthlyDelivery,setMonthlyDelivery] = useState(0);
    const [monthlyReply,setMonthlyReply] = useState(0);
    const [monthlyStop,setMonthlyStop] = useState(0);

    const today = () => {
        //delivery
        try{
            ReportsService.getReportsToday().then(response => {
                setTodaysDelivery(response.data.delivery)
                setTodaysReply(response.data.reply)
                setTodaysStop(response.data.stop)
                chart(response.data.delivery,response.data.reply,response.data.stop)
            });
        }
        catch(err){
            console.log('yow')
        }
        finally {
        }
    }

    const weekly = () => {
        //delivery
        try{
            ReportsService.getReportsWeekly().then(response => {
                setWeeklyDelivery(response.data.delivery)
                setWeeklyReply(response.data.reply)
                setWeeklyStop(response.data.stop)
                chartWeekly(response.data.delivery,response.data.reply,response.data.stop)
            });
        }
        catch(err){
            console.log('yow')
        }
        finally {
        }
    }

    const monthly = () => {
        //delivery
        try{
            ReportsService.getReportsMonthly().then(response => {
                setMonthlyDelivery(response.data.delivery)
                setMonthlyReply(response.data.reply)
                setMonthlyStop(response.data.stop)
                chartMonthly(response.data.delivery,response.data.reply,response.data.stop)
            });
        }
        catch(err){
            console.log('yow')
        }
        finally {
            setBackDrop(false)
        }
    }

    const chart = (del,rep,st) => {
        let delivery;
        let reply;
        let stop;
        if(!del && !rep && !st){
            delivery=0;
            reply=0;
            stop=0;
        }
        else{
            delivery=del;
            reply=rep;
            stop=st;
        }

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Total Delivery', 'Total Stop', 'Total Reply'],
            datasets: [
                {
                    data: [delivery, stop, reply],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--cyan-600'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };
        setChartData(data);
        setChartOptions(options);
    }

    const chartWeekly = (del,rep,st) => {
        let delivery;
        let reply;
        let stop;
        if(!del && !rep && !st){
            delivery=0;
            reply=0;
            stop=0;
        }
        else{
            delivery=del;
            reply=rep;
            stop=st;
        }

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Total Delivery', 'Total Stop', 'Total Reply'],
            datasets: [
                {
                    data: [delivery, stop, reply],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--cyan-600'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };
        setChartDataWeekly(data);
        setChartOptionsWeekly(options);
    }

    const chartMonthly = (del,rep,st) => {
        let delivery;
        let reply;
        let stop;
        if(!del && !rep && !st){
            delivery=0;
            reply=0;
            stop=0;
        }
        else{
            delivery=del;
            reply=rep;
            stop=st;
        }

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['Total Delivery', 'Total Stop', 'Total Reply'],
            datasets: [
                {
                    data: [delivery, stop, reply],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--cyan-600'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };
        setChartDataMonthly(data);
        setChartOptionsMonthly(options);
    }

    useEffect(()=>{
        today();
        weekly();
        monthly();
    },[])
    return(
        <>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Dashboard</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </div>{/* /.col */}
                    </div>{/* /.row */}
                </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                {/* <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className="card" style={{width: '120px',margin: '0 auto'}}>
                            <Calendar value={date} onChange={(e) => setDate(e.value)} />
                        </div>
                    </div>
                    <div className='col-md-4'></div>
                </div> */}
                <div className="row m-0 p-0">
                
                <section className="content col-4">

                        {/* Todays report*/}
                
                    <div className="container-fluid">
                            {/* Small boxes (Stat box) */}
                            <div className="col">
                            <div className="col py-2">
                            <span className="row d-flex justify-content-center" style={{fontSize: "2rem"}}>Today</span>
                            <span>{moment().format('LL')}</span>
                        </div>
                            <div className="col col-12">
                                {/* small box */}
                                <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{todaysDelivery}</h3>
                                    <p>Total Delivery</p>
                                </div>
                                <div className="icon">
                                <i className="pi pi-send" style={{ fontSize: '3rem' }}></i>
                                </div>
                                <a href="delivery_reports?report=today" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col">
                                {/* small box */}
                                <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{todaysReply}<sup style={{fontSize: 20}}></sup></h3>
                                    <p>Total Reply</p>
                                </div>
                                <div className="icon">
                                <i className="pi pi-inbox" style={{ fontSize: '3rem' }}></i>
                                </div>
                                <a href="reply_reports?report=today" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col col-12">
                                {/* small box */}
                                <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{todaysStop}</h3>
                                    <p>Total Stop</p>
                                </div>
                                <div className="icon">
                                <i className="pi pi-user-minus" style={{ fontSize: '3rem' }}></i>
                                </div>
                                <a href="stop_reports?report=today" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            

                            {/* ./col */}
                            </div>
                        <div className="row-justify-content-md-center">
                            <div className="flex justify-content-center mb-0 py-5 ">
                                <Chart type="pie" data={chartData} options={chartOptions} className="w-full md:w-30rem"/>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="content col-4 ">

                        {/* Monthlys report */}


                        <div className="container-fluid">
                                {/* Small boxes (Stat box) */}
                                <div className="col">
                                <div className="col py-2">
                                <span className="row d-flex justify-content-center" style={{fontSize: "2rem"}}>Weekly</span>
                                <span>{moment().subtract(7, 'days').format('LL')} - {moment().format('LL')}</span>
                                </div>
                                <div className="col col-12">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{weeklyDelivery}</h3>
                                        <p>Total Delivery</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-send" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="delivery_reports?report=weekly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col col-12">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{weeklyReply}<sup style={{fontSize: 20}}></sup></h3>
                                        <p>Total Reply</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-inbox" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="reply_reports?report=weekly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col col-12">
                                    {/* small box */}
                                    <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{weeklyStop}</h3>
                                        <p>Total Stop</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-user-minus" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="stop_reports?report=weekly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}

                                {/* ./col */}
                                </div>
                                <div className="row-justify-content-md-center">
                            <div className="flex justify-content-center mb-0 py-5">
                                <Chart type="pie" data={chartDataWeekly} options={chartOptionsWeekly} className="w-full md:w-30rem" />
                            </div>
                        </div>
                        </div>
                </section> 

                <section className="content col-4 ">

                        {/* Monthlys report */}


                        <div className="container-fluid">
                                {/* Small boxes (Stat box) */}
                                <div className="col">
                                    
                                <div className="col py-2">
                                <span className="row d-flex justify-content-center" style={{fontSize: "2rem"}}>Monthly</span>
                                <span>{moment().subtract(30, 'days').format('LL')} - {moment().format('LL')}</span>
                                </div>
                                <div className="col col-12">
                                    
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{monthlyDelivery}</h3>
                                        <p>Total Delivery</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-send" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="delivery_reports?report=monthly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col col-12">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{monthlyReply}<sup style={{fontSize: 20}}></sup></h3>
                                        <p>Total Reply</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-inbox" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="reply_reports?report=monthly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col col-12">
                                    {/* small box */}
                                    <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{monthlyStop}</h3>
                                        <p>Total Stop</p>
                                    </div>
                                    <div className="icon">
                                    <i className="pi pi-user-minus" style={{ fontSize: '3rem' }}></i>
                                    </div>
                                    <a href="stop_reports?report=monthly" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                    </div>
                                </div>
                                {/* ./col */}

                                {/* ./col */}
                                </div>
                                <div className="row-justify-content-md-center">
                            <div className="flex justify-content-center mb-0 py-5">
                                <Chart type="pie" data={chartDataMonthly} options={chartOptionsMonthly} className="w-full md:w-30rem" />
                            </div>
                        </div>
                        </div>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backDrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                </section> 
                </div>        
            </div>
        </>
    );
}

export default Dashboard;