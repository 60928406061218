import React, {useEffect,Component} from 'react';
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import UserInfo from '../api/UserInfo';

const Menu = () => {
  const [name,setName] = React.useState();
  useEffect(() => {
    console.log(UserInfo.data)
    try{
      let userData =  UserInfo
      // setName(userData.data.name)
      setName('Admin')
    }catch(err){
      console.log(err)
    }
  }, []);

  const logout = () => {
    localStorage.setItem('user_info','')
    localStorage.clear(); 
    window.location.href="/"
  }

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">

          <a href="index3.html" className="brand-link">
          <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
          <span className="brand-text font-weight-light">SMS Portal</span>
          </a>


          <div className="sidebar">

          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
              <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
              </div>
              <div className="info">
              <a href="#" className="d-block">{name}</a>
              </div>
          </div>

          <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={() => window.location.href='/'}>
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Dashboard
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-sms"></i>
                  <p>
                    Campaign
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="campaign"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Create Campaign</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="sms-template"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Create Sms Template</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="api-template"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Create Api Template</p>
                    </a>
                  </li>
                </ul>
              </li>    
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-phone-square-alt"></i>
                  <p>
                    Contacts
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="contacts"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add/View</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="groups"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Group</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="senderid"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Sender ID</p>
                    </a>
                  </li>
                </ul>
              </li>  
              {UserInfo.data.user_type == 'admin'?
                <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-user"></i>
                  <p>
                    Users
                    <i className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="#" className="nav-link" onClick={() => window.location.href="user"}>
                      <i className="far fa-circle nav-icon"></i>
                      <p>Add/View</p>
                    </a>
                  </li>
                </ul>
              </li>   
              :
                []
              }        
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={() => window.location.href="delivery_reports"}>
                  <i className="nav-icon fas fa-list-alt"></i>
                  <p>
                    Delivery Reports
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={() => window.location.href="reply_reports"}>
                  <i className="nav-icon fas fa-list-alt"></i>
                  <p>
                    Reply Reports
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link" onClick={() => window.location.href="stop_reports"}>
                  <i className="nav-icon fas fa-list-alt"></i>
                  <p>
                    Stop Reports
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas fa-cog"></i>
                  <p>
                    Settings
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" onClick={() => logout()} className="nav-link">
                  <i className="nav-icon fas fa-sign-out-alt"></i>
                  <p>
                    Logout
                  </p>
                </a>
              </li>
            </ul>
          </nav>

          </div>

      </aside>
    </div>

  )
}
export default Menu
