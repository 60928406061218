import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Users = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [userList,setUserList] = React.useState([])

    // const [companyCodeEd,setCompanyCodeEd] = React.useState(null)
    // const [regCompNameEd,setRegCompNameEd] = React.useState(null)
    // const [businessAddressEd,setBusinessAddressEd] = React.useState(null)
    // const [postalCodeEd,setPostalCodeEd] = React.useState(null)
    // const [cityEd,setCityEd] = React.useState(null)
    // const [countryEd,setCountryEd] = React.useState(null)
    // const [emailEd,setEmailEd] = React.useState(null)
    // const [contactPersonEd,setContactPersonEd] = React.useState(null)
    // const [contactNumberEd,setContactNumberEd] = React.useState(null)
    // const [ownerEd,setOwnerEd] = React.useState(null)
    // const [ownerContactNumberEd,setOwnerContactNumberEd] = React.useState(null)
    // const [tinNumberEd,setTinNumberEd] = React.useState(null)
    // const [termsOfPaymentEd,setTermsOfPaymentEd] = React.useState(null) 
    // const [customerListStatus,setCustomerListStatus] = React.useState(null)

    const [loadingCust,setLoadingCust] = React.useState(false)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);

    const [openNotif, setOpenNotif] = React.useState(false);
  
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        setLoadingCust(true);
        userListFunc()
    }, []);

    const userListFunc = () => {
        try{
            UserService.getUserList().then((response) => {
             console.log(response.data.message);
             setLoadingCust(false);
             setUserList(response.data.message);
            });
        }catch(err){
            setLoadingCust(false);
            console.log(err)
        }
    }

    const submitUser = () => {
        if(!name){
            return Swal.fire(
            'Error',
            'Name is required',
            'error'
            )
        }

        if(!email){
            return Swal.fire(
            'Error',
            'Email Address is required',
            'error'
            )
        }

        if(!userType){
            return Swal.fire(
            'Error',
            'User Type is required',
            'error'
            )
        }


        if(!password){
            return Swal.fire(
            'Error',
            'Password is required',
            'error'
            )
        }

        if(password != cpassword){
            return Swal.fire(
            'Error',
            'Password not match',
            'error'
            )
        }

        setBackDrop(true)
        let postData
        postData = {
            name: name,
            email: email,
            user_type: userType,
            password: password,
        }

        try{
            UserService.addUser(postData).then((response) => {
                setBackDrop(false)
                setFname('')
                setLname('')
                setName('')
                setEmail('')
                setUserType('')
                setPassword('')
                setCPassword('')
                userListFunc()
                return Swal.fire(
                    'Success',
                    response.data.message,
                    'success'
                    )
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setBackDrop(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setBackDrop(false)
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            submitUser()
        }
    }

    const handleUserListOption = () => {

    }

    // const onPressEnterEd = (e) => {
    //     if(e.keyCode === 13){
    //         updateForm()
    //     }
    // }

    // const handleCustomerListOption = (companyCode,status) => {
    //     setOpen(true)
    //     setCompanyCodeList(companyCode)
    //     setCustomerListStatus(status)
    // }

    // const editForm = () => {
    //     setOpenCustomerList(true)
    //     try{
    //         CustomerService.editForm(companyCodeList).then((response) => {
    //             console.log(response.data)
    //             setBackDrop(false)
    //             setCompanyCodeEd(response.data[0].company_code)
    //             setRegCompNameEd(response.data[0].company_name)
    //             setBusinessAddressEd(response.data[0].address)
    //             setPostalCodeEd(response.data[0].postal_code)
    //             setCityEd(response.data[0].city)
    //             setCountryEd(response.data[0].country)
    //             setEmailEd(response.data[0].email)
    //             setContactPersonEd(response.data[0].contact_person)
    //             setContactNumberEd(response.data[0].contact)
    //             setOwnerEd(response.data[0].owner)
    //             setOwnerContactNumberEd(response.data[0].contact_owner)
    //             setTinNumberEd(response.data[0].tin)
    //             setTermsOfPaymentEd(response.data[0].terms_payment) 
    //             $('#validity_start_date_val_ed').val(response.data[0].validity_start_date)
    //             $('#validity_end_date_val_ed').val(response.data[0].validity_end_date)
    //             // return Swal.fire(
    //             //     'Success',
    //             //     response.data.message,
    //             //     'success'
    //             //     )
    //         })
    //         .catch((error) => {
    //             console.log(error.response.data.message)
    //             setBackDrop(false)
    //             return Swal.fire(
    //                 'Error',
    //                 error.response.data.message,
    //                 'error'
    //                 )
    //         })
    //     }catch(err){
    //         console.log(err)
    //         setBackDrop(false)
    //     }
    // }

    // const deleteFunc = () => {
    //     setOpen(false)
    //     Swal.fire({
    //     title: 'Do you want to disable this customer?',
    //     showDenyButton: true,
    //     showCancelButton: false,
    //     confirmButtonText: 'Yes',
    //     denyButtonText: `No`,
    //     }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
    //             setBackDrop(true)
    //             try{
    //                 CustomerService.deleteCustomer(companyCodeList).then((response) => {
    //                     setBackDrop(false)
    //                     customerListFunc()
    //                     return Swal.fire(
    //                         'Success',
    //                          response.data.message,
    //                         'success'
    //                         )
    //                 })
    //             }catch(err){
    //                 console.log(err)
    //                 setBackDrop(false)
    //             }
    //         }
    //         else{
    //             setOpen(true)
    //         }
    //     })
    // }

    // const enableFunc = () => {
    //     setOpen(false)
    //     Swal.fire({
    //     title: 'Do you want to enable this customer?',
    //     showDenyButton: true,
    //     showCancelButton: false,
    //     confirmButtonText: 'Yes',
    //     denyButtonText: `No`,
    //     }).then((result) => {
    //     /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
    //             setBackDrop(true)
    //             try{
    //                 CustomerService.enableCustomer(companyCodeList).then((response) => {
    //                     setBackDrop(false)
    //                     customerListFunc()
    //                     return Swal.fire(
    //                         'Success',
    //                          response.data.message,
    //                         'success'
    //                         )
    //                 })
    //             }catch(err){
    //                 console.log(err)
    //                 setBackDrop(false)
    //             }
    //         }
    //         else{
    //             setOpen(true)
    //         }
    //     })
    // }

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Users</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Users</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#list" data-toggle="tab">List</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">     
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" id="customer_add" action="customer/add" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name</label>
                                                                <input type="text" onKeyUp={(e) => onPressEnter(e)} value={name} onChange={(e) => setName(e.target.value.toUpperCase())} required className="form-control" placeholder="First Name" />
                                                            </div>                   
                                                            <div className="form-group">
                                                                <label className="form-label">Email Address</label>
                                                                <input type="email" onKeyUp={(e) => onPressEnter(e)} value={email} name="email" onChange={(e) => setEmail(e.target.value)} required className="form-control" placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label className="form-label">User Type</label>
                                                                <select className='form-control' value={userType} onChange={(e) => setUserType(e.target.value)}>
                                                                    <option selected disabled>Select User Type</option>
                                                                    <option value="admin">Admin</option>
                                                                    <option value="agent">Agent</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Password</label>
                                                                <input type="password" onKeyUp={(e) => onPressEnter(e)} value={password} name="city" onChange={(e) => setPassword(e.target.value)} required className="form-control" placeholder="Password" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label">Confirm Password</label>
                                                                <input type="password" onKeyUp={(e) => onPressEnter(e)} value={cpassword} name="country" onChange={(e) => setCPassword(e.target.value)} required className="form-control" placeholder="Confirm Password" />
                                                            </div>
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => submitUser()} className="btn btn-success" style={{float: 'right'}}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="list">
                                                <div className="table-responsive">
                                                    <table id="customer" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>User Type</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {loadingCust?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                    <>
                                                                    {userList.map((option, index) => (
                                                                    <tr onDoubleClick={(e) => handleUserListOption(option.id)}>
                                                                            <td>{option.name}</td>
                                                                            <td>{option.email}</td>
                                                                            <td>{option.user_type}</td>
                                                                            {option.status == 1?
                                                                                <td><span className='badge bg-success'>Active</span></td>
                                                                            :
                                                                                <td><span className='badge bg-danger'>Inactive</span></td>
                                                                            }
                                                                    </tr>
                                                                    ))}
                                                                    </>
                                                                }                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{textAlign:'center'}}>
                                                        {companyCodeList}
                                                    </Typography>
                                                        <List>
                                                            <ListItem disablePadding>
                                                                <ListItemButton onClick={() => editForm()}>
                                                                    <ListItemIcon>
                                                                        <EditIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Edit" />
                                                                </ListItemButton>
                                                            </ListItem>
                                                            {customerListStatus == 1?
                                                                <ListItem disablePadding>
                                                                    <ListItemButton onClick={() => deleteFunc()}>
                                                                        <ListItemIcon>
                                                                            <CloseIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary="Disable" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            :
                                                                <ListItem disablePadding>
                                                                    <ListItemButton onClick={() => enableFunc()}>
                                                                        <ListItemIcon>
                                                                            <CheckIcon />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary="Enable" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            }
                                                        </List>
                                                    </Box>
                                                </Modal>
                                                <Modal
                                                open={openCustomerList}
                                                onClose={handleCustomerList}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>

                                                </Box>
                                                </Modal> */}
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default Users;