import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ContactService from '../../api/ContactService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SmsService from '../../api/SmsService'
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Select from 'react-select';

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditSmsTemplate = (props) => {
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [idForm,setIdForm] = React.useState(null)
    const [alertMSG,setAlertMSG] = React.useState(null)
    const [groups,setGroups] = React.useState([])
    const [group,setGroup] = React.useState([])
    const [contacts,setContacts] = React.useState([])
    const [to,setTo] = React.useState([])

    const [title,setTitle] = React.useState(props.title)
    const [template,setTemplate] = React.useState(props.template)
    const [smsID,setSmsID] = React.useState(props.contactID)
    const [openB, setOpenB] = React.useState(false);
    const [visible, setVisible] = useState(false);
    
    const [countChar, setCountChar] = React.useState(420-props.template.length)


    const handleCloseB = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenB(false);
    };

    useEffect(() => {
        getGroup()
        getSenderID()
    }, []);

    const getSenderID = () => {
        try{
            ContactService.getSenderIDListEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].phone,
                        label:response.data.message[x].phone
                    })
                }
             
                setContacts(senderidArr)
            });
        }catch(err){
            console.log(err)
        }
    }

    const getGroup = () => {
        try{
            ContactService.getGroupistEnabled().then((response) => {
                console.log(response.data.message);
                let senderidArr=[];
                for(let x=0;x<=response.data.message.length-1;x++){
                    senderidArr.push({
                        value:response.data.message[x].description,
                        label:response.data.message[x].description
                    })
                }
             
                setGroups(senderidArr)
            });
        }catch(err){
            console.log(err)
        }
    }


    const contactListFunc = () => {
        setLoadingContact(true)
        try{
            ContactService.getContactList().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    name:response.data.message[x].name,
                    phone:response.data.message[x].phone,
                    email:response.data.message[x].email,
                    birthday:response.data.message[x].birthday,
                    subscribe:response.data.message[x].subscribe,
                    status:response.data.message[x].status,
                })
             }
             setContactList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }

    const updateContact = () => {
        if(!title){
            return Swal.fire(
            'Error',
            'Title is required',
            'error'
            )
        }

        if(!template){
            return Swal.fire(
            'Error',
            'Template is required',
            'error'
            )
        }

        setLoadingContact(true)
        let postData
        postData = {
            id:smsID,
            title: title,
            template: template,
        }

        try{
            SmsService.updateSmsTemplate(postData).then((response) => {
                setLoadingContact(false)
                contactListFunc()
                setAlertMSG('Sms Template Updated Successfully')
                setOpenB(true)
                let timerInterval;
                Swal.fire({
                  title: "Success",
                  position: "top-end",
                  icon: 'success',
                  html: "Will refresh in <b></b>",
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                      timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                    window.location.reload()
                  }
                });
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setLoadingContact(false)
                return Swal.fire(
                    'Error',
                    error.response.data.message,
                    'error'
                    )
            })
        }catch(err){
            console.log(err)
            setLoadingContact(false)
        }
    }
    const addMergeTag = (event) => {
        setVisible(false)
        var targ = event.target || event.srcElement;
        let temp = '{{'+targ.textContent+'}}' || '{{'+targ.innerText+'}}';
        console.log(temp)
        setTemplate(template => [template + temp.substring(0)])
    }

    const setMsgFunc = (message) => {
        setTemplate(message)
        let countCharTotal = 420-message.length;
        setCountChar(countCharTotal)
        // var format = /[`!@#$%^&*()_+\-=\[\]{};'"\\|<>\/?~]/;
        // if(format.test(message) == true){
        //     setAlertMSG('Special Characters are Not Allowed')
        //     setOpenC(true)
        //     // setMsg('')
        //     // setCountChar(420)
        // }
        // else{
        //     setMsg(message)
        //     let countCharTotal = 420-message.length;
        //     setCountChar(countCharTotal)
        // }

    }

    return (
        <>
                <h2>Edit Sms Template</h2>
                {loadingContact == true?
                    <Box sx={{ display: 'flex' }}>
                        <Skeleton variant="rounded" width={'100%'} height={300} />
                    </Box>
                :
                    <form className="form-horizontal" method="POST">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Title</label>
                                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required className="form-control" placeholder="Enter Title" />
                                </div>         
                            </div>
                            <div className="col-md-8">
                                <label className="form-label">Template</label>
                                <textarea className="form-control"  max maxLength={420} style={{height: '250px'}} id="template-sms" placeholder="Enter Sms Template" value={template} onChange={(e) => setMsgFunc(e.target.value)}>

                                </textarea>
                                <i>{countChar} Characters left</i>
                            </div>
                        </div>   
                    </form>
                }
                <button type="button" onClick={() => updateContact()} className="btn btn-success" style={{float: 'right'}}>Update</button>
                <Snackbar open={openB} autoHideDuration={6000} onClose={handleCloseB}>
                    <Alert onClose={handleCloseB} severity="success" sx={{ width: '100%' }}>
                    {alertMSG}
                    </Alert>
                </Snackbar>
        </>
    );
}

export default EditSmsTemplate;