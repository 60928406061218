import React, {useState,useEffect} from 'react';
import UserService from '../../api/UserService'
import ReportsService from '../../api/ReportsService'
import SmsService from '../../api/SmsService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import { Button } from 'primereact/button';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import moment from 'moment';

import { Calendar } from 'primereact/calendar';
import { ConstructionSharp } from '@mui/icons-material';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const DeliveryReports = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [contactlist,setContactList] = React.useState([])
    const [reportList,setReportList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const handleEditForm= () => setOpenEditForm(false);

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [openNotif, setOpenNotif] = React.useState(false);
    const [date, setDate] = useState(null);
    const [dates, setDates] = useState(null);
    const [myArr,setMyArr] = useState([])
    const [loading,setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        message_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        msg_content: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        scheduled_date: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        sender_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        created_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        searchParam()
    }, []);

    const replyReportList = (date,selection) => {
        console.log(date)
        
        if(selection == 'one'){
            moment(date).format("YYYY-MM-DD")
            setDate(date)
            setDates(null)
            setLoading(true)
            getReplyReport(moment(date).format("YYYY-MM-DD"))
        }
        else{
            
            moment(date).format("YYYY-MM-DD")
            setDates(date)
            setDate(null)
        }

    }

    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;
    
    const getReplyReport = (data) => {
        let count;
        let formattedDateArray;
        if(data.constructor === Array){
            formattedDateArray = data.map(date => moment(date).format('YYYY-MM-DD'));
            console.log('array')
            console.log(data)
            count=2
        }
        else{
            formattedDateArray = data
            console.log('not array')
            count=1
        }
        try{
            ReportsService.getDeliveryReportList(formattedDateArray,count).then((response) => {
             setLoadingContact(false)
             
             let contactArr=[];
             for( let x=0;x<=response.data.message.length-1;x++){
                let status;
                if(typeof response.data.message[x].delivery_status[0] === "undefined"){
                    status="NOT YET DELIVERED"
                }
                else{
                    status=response.data.message[x].delivery_status[0].status
                }
                contactArr.push({

                    id:response.data.message[x].id,
                    message_id:response.data.message[x].message_id,
                    phone:response.data.message[x].phone,
                    msg_content:response.data.message[x].msg_content,
                    scheduled_date:moment(response.data.message[x].scheduled_date).format('LLL'),
                    sender_id:response.data.message[x].sender_id,
                    status:status,
                    created_at:moment(response.data.message[x].created_at).format('LLL'),
                })
             }
             setLoading(false)
             setMyArr(contactArr)
             console.log(contactArr)
            //  setReportList(contactArr);
            //  console.log(response.data.message)
             
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const submitDateRange = () => {
        console.log(dates[1])
        setLoading(true)
        if(dates[1] != null){
            getReplyReport(dates)
        }
        else{
            setLoading(false)
        }
    }

    const statusBodyTemplate = (rowData) => {
            if(rowData.status == "DELIVERED_TO_GATEWAY"){
                return <Tag value={rowData.status} severity={'success'}></Tag>;
            }
            else{
                return <Tag value={rowData.status} severity={'danger'}></Tag>;
            }
    

    };

    const searchParam = async() => {
        let url = new URLSearchParams(window.location.search);
        const query = url;
        const param = query.get('report');
        
        if(param == 'today'){
            setDate(new Date(moment().format('LL')))
            replyReportList(new Date(moment().format('LL')),'one')
        }
        if(param == 'weekly'){
            try{
                let sdate = new Date(moment().format('LL'));
                let fdate = new Date(moment().subtract(7, 'days').format('LL'))
                let mergeDate = [];
                mergeDate.push(fdate);
                mergeDate.push(sdate);
                setDates(mergeDate)
                setTimeout(() => {
                    document.getElementById('dateRange').click();
                },1000)
                
            }
            catch(err){
                console.log(err)
            }
        }
        if(param == 'monthly'){
            try{
                let sdate = new Date(moment().format('LL'));
                let fdate = new Date(moment().subtract(30, 'days').format('LL'))
                let mergeDate = [];
                mergeDate.push(fdate);
                mergeDate.push(sdate);
                setDates(mergeDate)
                setTimeout(() => {
                    document.getElementById('dateRange').click();
                },1000)
                
            }
            catch(err){
                console.log(err)
            }
        }
    }

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Delivery Reports</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Delivery Reports</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">     
                                        <div className="table-responsive">
                                            <div className="card">
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                                <div className="card flex justify-content-center">
                                                                    <Calendar placeholder="Select Date" variant="filled" value={date} onChange={(e) => replyReportList(e.value,'one')} />

                                                                </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                                    <Calendar placeholder="Select Date Range" value={dates}  onChange={(e) => replyReportList(e.value,'range')} selectionMode="range" readOnlyInput hideOnRangeSelection />
                                                                    <Button label="Submit Date Range" id="dateRange" onClick={submitDateRange}/>
                                                    </div>
                                                </div>
                                                <DataTable 
                                                sortMode="multiple" filters={filters}
                                                filterDisplay="row" paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                                loading={loading} value={myArr} tableStyle={{ minWidth: '50rem'}}  style={{ fontSize: '0.9rem' }}>
                                                    <Column sortable field="scheduled_date" header="Scheduled Delivery Date"></Column>
                                                    <Column sortable filter field="phone" header="Phone"></Column>
                                                    <Column sortable filter field="msg_content" header="Message Content"></Column>
                                                    <Column sortable filter field="sender_id" header="Sender ID"></Column>
                                                    <Column sortable field="message_id" header="Message ID"></Column>
                                                    <Column sortable field="created_at" header="Created Date"></Column>
                                                    <Column sortable body={statusBodyTemplate} header="Status"></Column>
                                                </DataTable>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default DeliveryReports;