// const API_HOST = "193.203.160.231"
// const API_HOST = "localhost";
// const API_HOST = "sms.iceman.systems"
// const API_HOST = "dev.iceman.systems"
// const API_HOST = "http://localhost";
// const API_HOST = "sms.iceman.systems"
//HTTPS
const API_HOST = "https://devsms.iceman.systems"
//HTTP
// const API_HOST = "https://sms.iceman.systems"
// const API_HOST = "localhost:8002";
const API_ROOT = "telpro-sms-api"

const HostConnect = {
    API_HOST,
    API_ROOT
}

export default HostConnect;