import React, {useState,useEffect} from 'react';
import AIService from '../../api/AIService'
import TTSService from '../../api/TTSService'
import ContactService from '../../api/ContactService'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2'
import $ from 'jquery'
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import SmsIcon from '@mui/icons-material/Sms';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CheckIcon from '@mui/icons-material/Check';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const styleCustomerList = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const VoiceAI = () => {
    const [backDrop,setBackDrop] = React.useState(false);
    const [fname,setFname] = React.useState(null)
    const [lname,setLname] = React.useState(null)
    const [name,setName] = React.useState(null)
    const [title,setTitle] = React.useState(null)
    const [text,setText] = React.useState(null)
    const [email,setEmail] = React.useState(null)
    const [userType,setUserType] = React.useState(null)
    const [password,setPassword] = React.useState(null)
    const [cpassword,setCPassword] = React.useState(null)
    const [ttslist,setTTSList] = React.useState([])
    const [phone,setPhone] = React.useState(null)
    const [birthday,setBirthday] = React.useState(null)
    const [subscribe,setSubscribe] = React.useState(null)
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const handleEditForm= () => setOpenEditForm(false);
    const [countChar, setCountChar] = React.useState(420)
    const [openC, setOpenC] = React.useState(false);

    const [openSmsForm, setOpenSmsForm] = React.useState(false);
    const handleSmsForm= () => setOpenSmsForm(false);

    const [loadingContact,setLoadingContact] = React.useState(false)
    const [editFormContent,setEditFormContent] = React.useState(null)
    const [editSmsContent,setEditSmsContent] = React.useState(null)
    const [alertMSG,setAlertMSG] = React.useState(null)


    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [openCustomerList, setOpenCustomerList] = React.useState(false);
    const handleCustomerList= () => openCustomerList(false);

    const [openNotif, setOpenNotif] = React.useState(false);
  
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenNotif(false);
    };

    const [noticSeverity,setNoticSeverity] = React.useState('success')
    const [noticeMSG,setNoticeMSG] = React.useState(null)

    useEffect(() => {
        ttsListFunc()
    }, []);

    const ttsListFunc = () => {
        setLoadingContact(true)
        try{
            TTSService.list().then((response) => {
             setLoadingContact(false)
             console.log(response.data.message);
            
             let contactArr=[];
             let count=1;
             for( let x=0;x<=response.data.message.length-1;x++){
                contactArr.push({
                    count:count++,
                    id:response.data.message[x].id,
                    title:response.data.message[x].title,
                    text:response.data.message[x].text,
                    audio_code:response.data.message[x].audio_code,
                    status:response.data.message[x].status,
                })
             }
             setTTSList(contactArr);
            });
        }catch(err){
            setLoadingContact(false);
            console.log(err)
        }
    }

    const submitContact = () => {
        setBackDrop(true)
        try{
            TTSService.create(title,text).then((response) => {

                console.log(response)
                setBackDrop(false)
                // setAudioURL(response);
                ttsListFunc()
                Swal.fire(
                    'Success',
                    'Successfully convert text to audio',
                    'success'
                    )
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setBackDrop(false)

            })
        }catch(err){
            console.log(err)
            
        }
    }

    const onPressEnter = (e) => {
        if(e.keyCode === 13){
            submitContact()
        }
    }

    const handleUserListOption = () => {

    }

    const setPhoneFunc = (numb) => {
        if(typeof numb !== 'number' && isNaN(numb)){
            setPhone('')
        }
        else{
            setPhone(numb)
        }
    }


    const editFunc = (id) => {
        setOpenEditForm(true)

    }

    const textFunc = (message) => {


        var format = /[`!#$%^&*()_+\-=\[\]{};'"\\|<>\/?~]/;
        if(format.test(message) == true){
            setAlertMSG('Special Characters are Not Allowed')
            setOpenC(true)
            // setMsg('')
            // setCountChar(420)
        }
        else{
            setText(message)
            let countCharTotal = 420-message.length;
            setCountChar(countCharTotal)
        }
    }

    const handleCloseC = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenC(false);
    };

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                        <h1>Voice AI TTS</h1>
                        </div>
                        <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href="#" onClick={() => window.location.href="/"}>Home</a></li>
                            <li className="breadcrumb-item active">Voice AI TTS</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#create" data-toggle="tab">Create</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#list" data-toggle="tab">List</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">     
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="create">
                                                <form className="form-horizontal" method="POST">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">AI Agent Name</label>
                                                                <input type="text" value={name} onChange={(e) => setTitle(e.target.value)} required className="form-control" placeholder="Enter AI Agent Name" />
                                                            </div>    
                                                            <div className="form-group">
                                                                <label className="form-label">Text</label>
                                                                <textarea maxLength={420} style={{height: '250px'}} value={name} onChange={(e) => textFunc(e.target.value)} required className="form-control" placeholder="Enter Text"></textarea>
                                                                <i>{countChar} Characters left</i>
                                                            </div>         
                                                        </div>
                                                    </div>   
                                                    <div className="card-footer">
                                                        <button type="button" onClick={() => submitContact()} className="btn btn-success" style={{float: 'right'}}>Create</button>
      {/* {audioURL && (
        <audio autoPlay controls>
          <source src={audioURL} type="audio/mpeg" />
        </audio>
      )} */}
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="tab-pane" id="list">
                                                <div className="table-responsive">
                                                    <table id="customer" className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Title</th>
                                                                <th>Text</th>
                                                                <th>Audio</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                {loadingContact?
                                                                    <LinearProgress color="inherit" />
                                                                :
                                                                    <>
                                                                    {ttslist.map((option, index) => (
                                                                    <tr onDoubleClick={(e) => handleUserListOption(option.id)}>
                                                                            <td>{option.count}</td>
                                                                            <td>{option.title}</td>
                                                                            <td>{option.text}</td>
                                                                            <td>
                                                                                <audio controls>
                                                                                    <source src={'data:audio/mp3;base64,'+option.audio_code} type="audio/mpeg" />
                                                                                </audio>
                                                                            </td>
                                                                            {option.status == 1?
                                                                                <td><span className='badge bg-success'>Active</span></td>
                                                                            :
                                                                                <td><span className='badge bg-danger'>Disabled</span></td>
                                                                            }
                                                                            <td>
                                                                            <Tooltip title="Edit">
                                                                                <IconButton aria-label="Edit" onClick={() => editFunc(option.id)} color="primary">
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            </td>
                                                                    </tr>
                                                                    ))}
                                                                    </>
                                                                }                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Modal
                                                open={openEditForm}
                                                onClose={handleEditForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editFormContent}
                                                    <button type="button" onClick={() => setOpenEditForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>

                                                <Modal
                                                open={openSmsForm}
                                                onClose={handleSmsForm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                >
                                                <Box sx={styleCustomerList}>
                                                    {editSmsContent}
                                                    <button type="button" onClick={() => setOpenSmsForm(false)} className="btn btn-danger">Close</button>
                                                </Box>
                                                </Modal>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={backDrop}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar open={openNotif} autoHideDuration={6000} onClose={handleCloseNotif}>
                                    <Alert onClose={handleCloseNotif} severity={noticSeverity} sx={{ width: '100%' }}>
                                    {noticeMSG}
                                    </Alert>
                                </Snackbar>
                            </div>          
                        </div>
                    </div>
                </section>            
            </div>
        </>
    )
}

export default VoiceAI;